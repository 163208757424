import { IBatchesCheckbox, ICreateUpdateUserPayload } from 'types';

export function setFilters({
  customization: { selectedOptions, filteredTicketRequest },
}: ICreateUpdateUserPayload) {
  selectedOptions.forEach((value, index) => {
    window.localStorage.setItem(`property${index + 1}`, value.childOption);
    window.localStorage.setItem(`status${index + 1}`, value.headerOption);
  });
  localStorage.setItem(
    'batchesPlants',
    JSON.stringify([
      ...filteredTicketRequest.plants.map(i => ({
        ...i,
        label: i.name,
        prefix: '+',
      })),
      ...filteredTicketRequest.notInPlants.map(i => ({
        ...i,
        label: i.name,
        prefix: '-',
      })),
    ]),
  );

  localStorage.setItem(
    'batchesCustomers',
    JSON.stringify([
      ...filteredTicketRequest.customers.map(i => ({
        ...i,
        label: i.name,
        prefix: '+',
      })),
      ...filteredTicketRequest.notInCustomers.map(i => ({
        ...i,
        label: i.name,
        prefix: '-',
      })),
    ]),
  );

  localStorage.setItem(
    'batchesMixDesigns',
    JSON.stringify([
      ...filteredTicketRequest.mixDesigns.map(i => ({
        ...i,
        label: i.name,
        prefix: '+',
      })),
      ...filteredTicketRequest.notInMixDesigns.map(i => ({
        ...i,
        label: i.name,
        prefix: '-',
      })),
    ]),
  );

  localStorage.setItem(
    'batchesVehicles',
    JSON.stringify([
      ...filteredTicketRequest.vehicles.map(i => ({
        ...i,
        label: i.name,
        prefix: '+',
      })),
      ...filteredTicketRequest.notInVehicles.map(i => ({
        ...i,
        label: i.name,
        prefix: '-',
      })),
    ]),
  );

  localStorage.setItem(
    'batchesDrivers',
    JSON.stringify([
      ...filteredTicketRequest.drivers.map(i => ({
        ...i,
        label: i.name,
        prefix: '+',
      })),
      ...filteredTicketRequest.notInDrivers.map(i => ({
        ...i,
        label: i.name,
        prefix: '-',
      })),
    ]),
  );

  localStorage.setItem(
    'batchesProjects',
    JSON.stringify([
      ...filteredTicketRequest.projects.map(i => ({
        ...i,
        label: i.name,
        prefix: '+',
      })),
      ...filteredTicketRequest.notInProjects.map(i => ({
        ...i,
        label: i.name,
        prefix: '-',
      })),
    ]),
  );

  localStorage.setItem(
    'selectedUsers',
    JSON.stringify([
      ...filteredTicketRequest.users.map(i => ({
        ...i,
        label: i.name,
        prefix: '+',
      })),
      ...filteredTicketRequest.notInUsers.map(i => ({
        ...i,
        label: i.name,
        prefix: '-',
      })),
    ]),
  );

  localStorage.setItem(
    'batchesPourEvents',
    JSON.stringify([
      ...filteredTicketRequest.pourEvents.map(i => ({
        additionalId: i.id,
        label: i.name,
        prefix: '+',
      })),
      ...filteredTicketRequest.notInPourEvents.map(i => ({
        additionalId: i.id,
        label: i.name,
        prefix: '-',
      })),
    ]),
  );

  localStorage.setItem(
    'batchesEventStatusCodes',
    JSON.stringify([
      ...filteredTicketRequest.eventStatusCodes.map(i => ({
        id: i,
        prefix: '+',
        label: i,
      })),
      ...filteredTicketRequest.notInEventStatusCodes.map(i => ({
        id: i,
        prefix: '-',
        label: i,
      })),
    ]),
  );

  console.log('here1');

  localStorage.setItem('batchesFrom', filteredTicketRequest.startDateTime);

  localStorage.setItem('batchesTo', filteredTicketRequest.endDateTime);
}

export function getInitialFilters() {
  const plants = JSON.parse(
    localStorage.getItem('batchesPlants') || '[]',
  ) as IBatchesCheckbox[];
  const customers = JSON.parse(
    localStorage.getItem('batchesCustomers') || '[]',
  ) as IBatchesCheckbox[];
  const mixDesigns = JSON.parse(
    localStorage.getItem('batchesMixDesigns') || '[]',
  ) as IBatchesCheckbox[];
  const vehicles = JSON.parse(
    localStorage.getItem('batchesVehicles') || '[]',
  ) as IBatchesCheckbox[];

  const drivers = JSON.parse(
    localStorage.getItem('batchesDrivers') || '[]',
  ) as IBatchesCheckbox[];
  const projects = JSON.parse(
    localStorage.getItem('batchesProjects') || '[]',
  ) as IBatchesCheckbox[];
  const pourEvents = JSON.parse(
    localStorage.getItem('batchesPourEvents') || '[]',
  ) as IBatchesCheckbox[];
  const eventStatusCodes = JSON.parse(
    localStorage.getItem('batchesEventStatusCodes') || '[]',
  ) as IBatchesCheckbox[];
  const users = JSON.parse(
    localStorage.getItem('selectedUsers') || '[]',
  ) as IBatchesCheckbox[];
  const startDate =
    !localStorage.getItem('batchesFrom') ||
    localStorage.getItem('batchesFrom') === 'null'
      ? localStorage.getItem('batchesFrom') === 'null'
        ? null
        : null
      : new Date(localStorage.getItem('batchesFrom')! || '');
  const endDate =
    !localStorage.getItem('batchesTo') ||
    localStorage.getItem('batchesTo') === 'null'
      ? null
      : new Date(localStorage.getItem('batchesTo')! || '');

  return {
    plants,
    customers,
    mixDesigns,
    vehicles,
    drivers,
    projects,
    pourEvents,
    eventStatusCodes,
    users,
    startDate,
    endDate,
  };
}

export function getSelectedEntitiesIds({
  customers,
  drivers,
  eventStatusCodes,
  mixDesigns,
  plants,
  pourEvents,
  projects,
  users,
  vehicles,
}: Omit<ReturnType<typeof getInitialFilters>, 'startDate' | 'endDate'>) {
  return {
    plants: plants
      .filter(el => el.prefix === '+')
      .map(el => ({ id: el.id, name: el.label })),
    mixDesigns: mixDesigns
      .filter(el => el.prefix === '+')
      .map(el => ({ id: el.id, name: el.label })),
    vehicles: vehicles
      .filter(el => el.prefix === '+')
      .map(el => ({ id: el.id, name: el.label })),
    drivers: drivers
      .filter(el => el.prefix === '+')
      .map(el => ({ id: el.id, name: el.label })),
    customers: customers
      .filter(el => el.prefix === '+')
      .map(el => ({ id: el.id, name: el.label })),
    pourEvents: pourEvents
      .filter(el => el.prefix === '+')
      .map(el => ({ id: el.additionalId || '', name: el.label })),
    projects: projects
      .filter(el => el.prefix === '+')
      .map(el => ({ id: el.id, name: el.label })),
    eventStatusCodes: eventStatusCodes
      .filter(el => el.prefix === '+')
      .map(el => el.id),
    users: users
      .filter(el => el.prefix === '+')
      .map(el => ({ id: el.id, name: el.label })),
    notInMixDesigns: mixDesigns
      .filter(el => el.prefix === '-')
      .map(el => ({ id: el.id, name: el.label })),
    notInPlants: plants
      .filter(el => el.prefix === '-')
      .map(el => ({ id: el.id, name: el.label })),
    notInVehicles: vehicles
      .filter(el => el.prefix === '-')
      .map(el => ({ id: el.id, name: el.label })),
    notInDrivers: drivers
      .filter(el => el.prefix === '-')
      .map(el => ({ id: el.id, name: el.label })),
    notInCustomers: customers
      .filter(el => el.prefix === '-')
      .map(el => ({ id: el.id, name: el.label })),
    notInPourEvents: pourEvents
      .filter(el => el.prefix === '-')
      .map(el => ({ id: el.additionalId || '', name: el.label })),
    notInProjects: projects
      .filter(el => el.prefix === '-')
      .map(el => ({ id: el.id, name: el.label })),
    notInEventStatusCodes: eventStatusCodes
      .filter(el => el.prefix === '-')
      .map(el => el.id),
    notInUsers: users
      .filter(el => el.prefix === '-')
      .map(el => ({ id: el.id, name: el.label })),
  };
}
