import { NotificationCode, NotificationCategory } from 'types/general';

export const getNotificationCategory = (value: string) => {
  switch (value) {
    case NotificationCode.TEMP_OUT_OF_RANGE_MIN:
    case NotificationCode.TEMP_OUT_OF_RANGE_MAX:
    case NotificationCode.TEMP_INCREASE_DURING_TRANSIT:
    case NotificationCode.TEMP_DECREASE_DURING_TRANSIT:
    case NotificationCode.TEMP_OVER_MAX:
    case NotificationCode.TEMP_UNDER_MIN: {
      return NotificationCategory.TEMPERATURE;
    }
    case NotificationCode.TEMP_CHANGE_FROM_PREV_BATCH: {
      return NotificationCategory.TEMP_PREV_BATCH;
    }
    case NotificationCode.SLUMP_OUT_OF_RANGE:
    case NotificationCode.SLUMP_OUT_OF_RANGE_AT_JOB:
    case NotificationCode.SLUMP_INCREASE_DURING_TRANSIT:
    case NotificationCode.SLUMP_DECREASE_DURING_TRANSIT: {
      return NotificationCategory.SLUMP;
    }
    case NotificationCode.SLUMP_INCREASE_OVER_PREV_BATCH:
    case NotificationCode.SLUMP_DECREASE_OVER_PREV_BATCH: {
      return NotificationCategory.SLUMP_PREV_BATCH;
    }
    case NotificationCode.BATCH_AGE:
    case NotificationCode.TRUCK_TIME_DELAY_AT_PLANT:
    case NotificationCode.TRUCK_TIME_DELAY_AT_JOB:
    case NotificationCode.TRUCK_TIME_DELAY_LOADING: {
      return NotificationCategory.TIME_DELAY;
    }
    case NotificationCode.REQUIRED_REVOLUTIONS: {
      return NotificationCategory.REVOLUTIONS;
    }
    case NotificationCode.WATER_CEMENT_RATIO_OUT_OF_RANGE_MAX: {
      return NotificationCategory.WATER_CEMENT_RATIO;
    }
    case NotificationCode.MULTIPLE_BATCHES_ADDING_WATER:
    case NotificationCode.WATER_ADDED: {
      return NotificationCategory.ADDING_WATER;
    }
    case NotificationCode.BATCH_AGGREGATE_TARGET_DEVIATION:
    case NotificationCode.BATCH_CEMENTITIOUS_TARGET_DEVIATION:
    case NotificationCode.BATCH_WATER_TARGET_DEVIATION:
    case NotificationCode.BATCH_ADMIXTURE_TARGET_DEVIATION: {
      return NotificationCategory.TARGET_DEVIATION;
    }
    case NotificationCode.REJECTED_LOAD: {
      return NotificationCategory.REJECTED_LOAD;
    }
    case NotificationCode.RETURNED_CONCRETE: {
      return NotificationCategory.RETURNED_CONCRETE;
    }
    case NotificationCode.AGGREGATE_MOISTURE_OUT_OF_RANGE_MAX:
    case NotificationCode.AGGREGATE_MOISTURE_OUT_OF_RANGE_MIN: {
      return NotificationCategory.MOISTURE;
    }
    case NotificationCode.SURFACE_EVAPORATION_RATE: {
      return NotificationCategory.SURFACE_EVAPORATION_RATE;
    }
    case NotificationCode.AIR_CONTENT_OFF_TARGET_AT_JOB:
    case NotificationCode.AIR_CONTENT_OFF_TARGET_AT_TRANSIT:
    case NotificationCode.AIR_CONTENT_OFF_TARGET_AT_PLANT: {
      return NotificationCategory.AIR_CONTENT;
    }
    default:
      return null;
  }
};
