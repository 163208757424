import { makeStyles } from '@material-ui/core';
import { themeColors } from 'app/shared/theme/theme';

export const useStyles = makeStyles({
  primary: {
    boxSizing: 'border-box',
    padding: '14px',
    backgroundColor: themeColors.mbsBlue,
    border: `1px solid ${themeColors.mbsBlue}`,
    borderRadius: '2px',
    cursor: 'pointer',
    minWidth: '180px',
    '&:disabled': {
      boxSizing: 'border-box',
      minWidth: '150px',
      border: `1px solid ${themeColors.greyscale3}`,
      borderRadius: '2px',
      backgroundColor: themeColors.greyscale3,
      cursor: 'not-allowed',
    },
  },
  primarySave: {
    boxSizing: 'border-box',
    padding: '10px 32px',
    backgroundColor: themeColors.mbsBlue,
    border: `1px solid ${themeColors.mbsBlue}`,
    borderRadius: '2px',
    cursor: 'pointer',
    minWidth: '150px',
    '&:disabled': {
      boxSizing: 'border-box',
      minWidth: '150px',
      border: `1px solid ${themeColors.greyscale3}`,
      borderRadius: '2px',
      backgroundColor: themeColors.greyscale3,
      cursor: 'not-allowed',
    },
  },
  primaryBig: {
    boxSizing: 'border-box',
    padding: '10px 32px',
    backgroundColor: themeColors.mbsBlue,
    border: `1px solid ${themeColors.mbsBlue}`,
    borderRadius: '2px',
    cursor: 'pointer',
    width: '100%',
    '&:disabled': {
      boxSizing: 'border-box',
      width: '100%',
      border: `1px solid ${themeColors.greyscale3}`,
      borderRadius: '2px',
      backgroundColor: themeColors.greyscale3,
      cursor: 'not-allowed',
    },
  },
  primaryCancel: {
    boxSizing: 'border-box',
    padding: '10px 32px',
    backgroundColor: themeColors.white,
    border: `1px solid ${themeColors.mbsBlue}`,
    borderRadius: '2px',
    cursor: 'pointer',
    minWidth: '150px',
    marginRight: 20,
  },
  secondaryCancel: {
    boxSizing: 'border-box',
    padding: '10px 32px',
    backgroundColor: themeColors.white,
    border: `1px solid ${themeColors.mbsBlue}`,
    borderRadius: '2px',
    cursor: 'pointer',
    marginRight: 20,
  },
  clickHere: {
    boxSizing: 'border-box',
    padding: '10px 32px',
    backgroundColor: themeColors.white,
    border: `1px solid ${themeColors.mbsBlue}`,
    borderRadius: '2px',
    cursor: 'pointer',
    minWidth: '100%',
  },
  primaryProfile: {
    minWidth: 107,
    boxSizing: 'border-box',
    padding: '7px 12px',
    backgroundColor: themeColors.greyscale5,
    border: `1px solid ${themeColors.mbsBlue}`,
    borderRadius: '2px',
    cursor: 'pointer',
  },
  secondaryProfile: {
    minWidth: 107,
    boxSizing: 'border-box',
    padding: '7px 12px',
    backgroundColor: themeColors.mbsBlue,
    border: `1px solid ${themeColors.mbsBlue}`,
    borderRadius: '2px',
    cursor: 'pointer',
  },
  primaryNotFound: {
    boxSizing: 'border-box',
    padding: '8px 24px',
    backgroundColor: themeColors.mbsOrange,
    border: `1px solid ${themeColors.mbsOrange}`,
    borderRadius: '2px',
    cursor: 'pointer',
  },
  secondaryLight: {
    boxSizing: 'border-box',
    padding: '7px 15px',
    backgroundColor: themeColors.white,
    border: `1px solid ${themeColors.mbsBlue}`,
    borderRadius: '2px',
    cursor: 'pointer',
    width: '69px',
  },
  secondaryDark: {
    boxSizing: 'border-box',
    padding: '7px 15px',
    backgroundColor: themeColors.mbsBlue,
    border: `1px solid ${themeColors.mbsBlue}`,
    borderRadius: '2px',
    cursor: 'pointer',
    minWidth: '69px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  secondaryBlack: {
    boxSizing: 'border-box',
    padding: '7px 15px',
    backgroundColor: themeColors.white,
    border: `1px solid ${themeColors.accentLight}`,
    borderRadius: '2px',
    cursor: 'pointer',
    width: '69px',
  },
  pagination: {
    boxSizing: 'border-box',
    padding: '10px 24px',
    border: `1px solid ${themeColors.mbsBlue}`,
    borderRadius: '2px',
    cursor: 'pointer',
    backgroundColor: themeColors.greyscale5,

    '&:disabled': {
      boxSizing: 'border-box',
      padding: '10px 24px',
      border: `1px solid ${themeColors.greyscale2}`,
      borderRadius: '2px',
      backgroundColor: themeColors.greyscale5,
    },
  },
  headerActive: {
    display: 'flex',
    alignItems: 'center',
    height: '36px',
    padding: '0px 16px',
    border: 'none',
    backgroundColor: themeColors.greyscale4_50,
    margin: '0px 8px 0px 15px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: '36px',
    padding: '0px 16px',
    border: 'none',
    backgroundColor: themeColors.white,
    margin: '0px 8px 0px 15px',
    cursor: 'pointer',
  },
  icon: {
    marginRight: '8px',
  },
  view: {
    backgroundColor: themeColors.greyscale4,
    height: 32,
    width: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    cursor: 'pointer',
    borderRadius: 2,
    '&:disabled': {
      cursor: 'not-allowed',
    },
  },
  activeView: {
    padding: '6px',
    backgroundColor: themeColors.mbsOrange,
    height: 32,
    width: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    cursor: 'pointer',
    borderRadius: 2,
  },
  filterSmall: {
    backgroundColor: themeColors.white,
    height: 32,
    width: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: 2,
    border: `1px solid ${themeColors.mbsBlue}`,
  },
  filterSmallActive: {
    backgroundColor: themeColors.mbsBlue,
    height: 32,
    width: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    cursor: 'pointer',
    borderRadius: 2,
    paddingLeft: 6,
  },
  filter: {
    boxSizing: 'border-box',
    padding: '7px 15px',
    backgroundColor: themeColors.white,
    border: `1px solid ${themeColors.mbsBlue}`,
    borderRadius: '2px',
    cursor: 'pointer',
    width: '79px',
    display: 'flex',
    flexDirection: 'row',
  },
  filterActive: {
    boxSizing: 'border-box',
    padding: '7px 15px',
    backgroundColor: themeColors.mbsBlue,
    border: `1px solid ${themeColors.mbsBlue}`,
    borderRadius: '2px',
    cursor: 'pointer',
    width: '79px',
    display: 'flex',
    flexDirection: 'row',
  },
  secondaryOutlined: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${themeColors.mbsBlue}`,
    cursor: 'pointer',
    width: 107,
    height: 28,
    marginTop: 2,
    backgroundColor: themeColors.white,
    borderRadius: 2,
  },
  primaryOutlined: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${themeColors.accentDark}`,
    cursor: 'pointer',
    padding: '12px 24px',
    height: 42,
    marginTop: 2,
    backgroundColor: themeColors.white,
    borderRadius: 2,
  },
  secondarySave: {
    minWidth: 107,
    boxSizing: 'border-box',
    padding: '7px',
    backgroundColor: themeColors.mbsBlue,
    border: `1px solid ${themeColors.mbsBlue}`,
    borderRadius: '2px',
    cursor: 'pointer',
    '&:disabled': {
      border: `1px solid ${themeColors.greyscale3}`,
      backgroundColor: themeColors.greyscale3,
      cursor: 'not-allowed',
    },
  },
  link: {
    backgroundColor: themeColors.greyscale5,
    border: 'none',
    paddingTop: 8,
    paddingLeft: 16,
    cursor: 'pointer',
  },
  clearAll: {
    backgroundColor: themeColors.white,
    border: 'none',
    paddingTop: 8,
    paddingLeft: 16,
    cursor: 'pointer',
  },
});
