import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { useStyles } from './BatchesFilterBlock.styles';
import { DatePicker } from 'app/shared/components/generic-ui/DatePicker/DatePicker';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { ReactComponent as CloseIcon } from 'assets/close.svg';
import { Button } from 'app/shared/components/generic-ui/Button/Button';
import { useBatchHistoryList, useScreen } from 'app/shared/hooks';
import { themeColors, themeLayout } from 'app/shared/theme/theme';
import {
  IBatchesCheckbox,
  IHistoryRecord,
  IMetaData,
  NotificationEntityType,
} from 'types/general';
import { EntitiesDropdown } from '../../EntitiesDropdown';
import { firebaseAnalytics } from 'app/shared/analytics';
import { useHistory, useParams } from 'react-router-dom';
import { Routes } from 'types';
import { format, startOfDay } from 'date-fns';
import { useAuth } from 'app/shared/providers';
import { EventStatusDropdown } from '../../EntitiesDropdown/EventStatusDropdown';
import { UsersDropdown } from '../../EntitiesDropdown/UsersDropdown';
import {
  getInitialFilters,
  getSelectedEntitiesIds,
} from 'app/pages/batches/utils';

interface IFilterBlockProps {
  withStatusesOnly: boolean;
  isUserAssignedEntities: boolean;
  loadingHandler: (value: boolean) => void;
  batchesHistoryHandler: (items: IHistoryRecord[], metadata: IMetaData) => void;
  refresh: boolean;
  toJobRemaining: boolean;
  predictions: boolean;
}

const skipOnePage = 1;

export const BatchesFilterBlock = ({
  withStatusesOnly,
  isUserAssignedEntities,
  batchesHistoryHandler,
  loadingHandler,
  refresh,
  toJobRemaining,
  predictions,
}: IFilterBlockProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    organizationId,
    page,
    perPage,
  }: { organizationId: string; page: string; perPage: string } = useParams();
  const { userId } = useAuth();
  const ticketId = new URLSearchParams(location.search).get('ticketId');
  const history = useHistory();

  const dateFilterFormat = useCallback((date: Date | undefined | null) => {
    return date
      ? format(
          startOfDay(new Date(date.toISOString())),
          "yyyy-MM-dd'T'HH:mm:ssxxx",
        )
      : '';
  }, []);

  const {
    customers,
    drivers,
    endDate: initialEndDate,
    eventStatusCodes,
    mixDesigns,
    plants,
    pourEvents,
    projects,
    startDate: initialStartDate,
    users,
    vehicles,
  } = getInitialFilters();

  const [selectedPlants, setSelectedPlants] =
    useState<IBatchesCheckbox[]>(plants);
  const [selectedCustomers, setSelectedCustomers] =
    useState<IBatchesCheckbox[]>(customers);
  const [selectedMixDesigns, setSelectedMixDesigns] =
    useState<IBatchesCheckbox[]>(mixDesigns);
  const [selectedVehicles, setSelectedVehicles] =
    useState<IBatchesCheckbox[]>(vehicles);
  const [selectedDrivers, setSelectedDrivers] =
    useState<IBatchesCheckbox[]>(drivers);
  const [selectedProjects, setSelectedProjects] =
    useState<IBatchesCheckbox[]>(projects);
  const [selectedPourEvents, setSelectedPourEvents] =
    useState<IBatchesCheckbox[]>(pourEvents);
  const [selectedEventStatusCodes, setSelectedEventStatusCodes] =
    useState<IBatchesCheckbox[]>(eventStatusCodes);
  const [selectedUsers, setSelectedUsers] = useState<IBatchesCheckbox[]>(users);

  const [startDate, setStartDate] = useState<Date | undefined | null>(
    initialStartDate,
  );
  const [endDate, setEndDate] = useState<Date | undefined | null>(
    initialEndDate,
  );

  const { isMobile } = useScreen(themeLayout.breakpointBig);

  const clearAllButtonHandler = useCallback(() => {
    setSelectedPlants([]);
    setSelectedCustomers([]);
    setSelectedDrivers([]);
    setSelectedMixDesigns([]);
    setSelectedPourEvents([]);
    setSelectedProjects([]);
    setSelectedVehicles([]);
    setSelectedEventStatusCodes([]);
    setSelectedUsers([]);
  }, []);

  const clearButton = useMemo(() => {
    return (
      <Button onClick={clearAllButtonHandler} height={42} variant="clearAll">
        <div className={classes.clearAll}>
          <CustomTypography variant="buttonTextSmall" bold color={'mbsBlue'}>
            {t(translations.filterBlock.clearAll)}
          </CustomTypography>

          <div className={classes.close}>
            <CloseIcon
              height={9}
              stroke={themeColors.mbsBlue}
              strokeWidth={3}
            />
          </div>
        </div>
      </Button>
    );
  }, [classes.clearAll, classes.close, clearAllButtonHandler, t]);

  const changeHandler = useCallback((from?: Date | null, to?: Date | null) => {
    setStartDate(from);
    setEndDate(to);
  }, []);

  const removePlantHandler = useCallback(
    (entity: IBatchesCheckbox, entityType: NotificationEntityType) => {
      switch (entityType) {
        case NotificationEntityType.PLANT: {
          setSelectedPlants(prevState =>
            prevState.filter(item => entity.id !== item.id),
          );
          if (!isUserAssignedEntities) {
            setSelectedCustomers([]);
            setSelectedMixDesigns([]);
            setSelectedDrivers([]);
            setSelectedVehicles([]);
            setSelectedProjects([]);
            setSelectedPourEvents([]);
          }

          break;
        }
        case NotificationEntityType.CUSTOMER: {
          setSelectedCustomers(prevState =>
            prevState.filter(item => entity.id !== item.id),
          );

          if (!isUserAssignedEntities) {
            setSelectedMixDesigns([]);
            setSelectedDrivers([]);
            setSelectedVehicles([]);
            setSelectedProjects([]);
            setSelectedPourEvents([]);
          }

          break;
        }
        case NotificationEntityType.MIX_DESIGN: {
          setSelectedMixDesigns(prevState =>
            prevState.filter(item => entity.id !== item.id),
          );

          if (!isUserAssignedEntities) {
            setSelectedDrivers([]);
            setSelectedVehicles([]);
            setSelectedProjects([]);
            setSelectedPourEvents([]);
          }

          break;
        }
        case NotificationEntityType.VEHICLE: {
          setSelectedVehicles(prevState =>
            prevState.filter(item => entity.id !== item.id),
          );

          if (!isUserAssignedEntities) {
            setSelectedDrivers([]);
            setSelectedProjects([]);
            setSelectedPourEvents([]);
          }

          break;
        }
        case NotificationEntityType.DRIVER: {
          setSelectedDrivers(prevState =>
            prevState.filter(item => entity.id !== item.id),
          );

          if (!isUserAssignedEntities) {
            setSelectedProjects([]);
            setSelectedPourEvents([]);
          }

          break;
        }
        case NotificationEntityType.PROJECT: {
          setSelectedProjects(prevState =>
            prevState.filter(item => entity.id !== item.id),
          );

          if (!isUserAssignedEntities) {
            setSelectedPourEvents([]);
          }

          break;
        }
        case NotificationEntityType.POUR_EVENT: {
          setSelectedPourEvents(prevState =>
            prevState.filter(item => entity.additionalId !== item.additionalId),
          );
          break;
        }
        default:
          break;
      }
    },
    [isUserAssignedEntities],
  );

  const { fetchBatchesHistory, isFetchingBatchesHistory } = useBatchHistoryList(
    {
      organizationId: organizationId,
    },
  );
  const selectedEntitiesIds = useMemo(() => {
    return getSelectedEntitiesIds({
      customers: selectedCustomers,
      drivers: selectedDrivers,
      eventStatusCodes: selectedEventStatusCodes,
      mixDesigns: selectedMixDesigns,
      plants: selectedPlants,
      pourEvents: selectedPourEvents,
      projects: selectedProjects,
      users: selectedUsers,
      vehicles: selectedVehicles,
    });
  }, [
    selectedPlants,
    selectedMixDesigns,
    selectedVehicles,
    selectedDrivers,
    selectedCustomers,
    selectedPourEvents,
    selectedProjects,
    selectedEventStatusCodes,
    selectedUsers,
  ]);

  const batchHistoryParams = useMemo(() => {
    const params = {
      skip: `${(+page - skipOnePage) * +perPage}`,
      take: perPage,
      startDateTime: startDate ? dateFilterFormat(startDate) : '',
      endDateTime: endDate
        ? dateFilterFormat(endDate)
        : !endDate && startDate
        ? dateFilterFormat(startDate)
        : '',
      lastElementDateTimeUtc: '',
      ticketsWithStatusesOnly: withStatusesOnly,
      toJobRemaining,
      predictions,
      ...selectedEntitiesIds,
    };

    return isUserAssignedEntities
      ? {
          ...params,
          userId,
          crossmatching: false,
        }
      : { ...params, crossmatching: true };
  }, [
    dateFilterFormat,
    endDate,
    isUserAssignedEntities,
    page,
    perPage,
    selectedEntitiesIds,
    startDate,
    userId,
    withStatusesOnly,
    toJobRemaining,
    predictions,
  ]);

  const applyFilterHandler = useCallback(() => {
    firebaseAnalytics.logPressEvent(
      Routes.Batches,
      'Apply filters for Batches',
      JSON.stringify(batchHistoryParams),
    );

    localStorage.setItem('batchesFrom', startDate?.toISOString() || '');
    localStorage.setItem('batchesTo', endDate?.toISOString() || '');
    localStorage.setItem('batchesPlants', JSON.stringify(selectedPlants || []));
    localStorage.setItem('batchesCustomers', JSON.stringify(selectedCustomers));
    localStorage.setItem(
      'batchesMixDesigns',
      JSON.stringify(selectedMixDesigns),
    );
    localStorage.setItem('batchesVehicles', JSON.stringify(selectedVehicles));
    localStorage.setItem('batchesDrivers', JSON.stringify(selectedDrivers));
    localStorage.setItem('batchesProjects', JSON.stringify(selectedProjects));
    localStorage.setItem(
      'batchesPourEvents',
      JSON.stringify(selectedPourEvents),
    );
    localStorage.setItem(
      'batchesEventStatusCodes',
      JSON.stringify(selectedEventStatusCodes),
    );

    localStorage.setItem('ticketId', '');
    localStorage.setItem('batchesPlants', JSON.stringify(selectedUsers || []));
    history.push(
      Routes.Batches.replace(
        ':organizationId/:page/:perPage',
        `${organizationId}/1/20`,
      ),
    );

    fetchBatchesHistory(batchHistoryParams).then(res =>
      batchesHistoryHandler(res.data, res.metadata),
    );
  }, [
    batchHistoryParams,
    startDate,
    endDate,
    selectedPlants,
    selectedCustomers,
    selectedMixDesigns,
    selectedVehicles,
    selectedDrivers,
    selectedProjects,
    selectedPourEvents,
    selectedEventStatusCodes,
    selectedUsers,
    history,
    organizationId,
    fetchBatchesHistory,
    batchesHistoryHandler,
  ]);

  useEffect(() => {
    if (!ticketId) {
      fetchBatchesHistory(batchHistoryParams).then(res =>
        batchesHistoryHandler(res.data, res.metadata),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    perPage,
    withStatusesOnly,
    isUserAssignedEntities,
    ticketId,
    refresh,
    toJobRemaining,
  ]);

  useEffect(() => {
    if (ticketId || isUserAssignedEntities) {
      clearAllButtonHandler();
    }
  }, [clearAllButtonHandler, ticketId, isUserAssignedEntities]);

  useEffect(() => {
    loadingHandler(isFetchingBatchesHistory);
  }, [isFetchingBatchesHistory, loadingHandler]);

  const dropdowns = useMemo(() => {
    return [
      {
        entityType: NotificationEntityType.PLANT,
        onChangeSelectedEntities: (
          values: string[],
          items?: IBatchesCheckbox[],
        ) => {
          setSelectedPlants(items || []);
        },
        selectedValues: selectedPlants.map(el => `${el.prefix}${el.id}`),
        selectedItems: selectedPlants,
        label: t(translations.filterBlock.plant),
      },
      {
        entityType: NotificationEntityType.CUSTOMER,
        onChangeSelectedEntities: (
          values: string[],
          items?: IBatchesCheckbox[],
        ) => {
          setSelectedCustomers(items || []);
        },
        selectedValues: selectedCustomers.map(el => `${el.prefix}${el.id}`),
        selectedItems: selectedCustomers,
        label: t(translations.filterBlock.customer),
      },
      {
        entityType: NotificationEntityType.MIX_DESIGN,
        onChangeSelectedEntities: (
          values: string[],
          items?: IBatchesCheckbox[],
        ) => {
          setSelectedMixDesigns(items || []);
        },
        selectedValues: selectedMixDesigns.map(el => `${el.prefix}${el.id}`),
        selectedItems: selectedMixDesigns,
        label: t(translations.filterBlock.mixDesign),
      },
      {
        entityType: NotificationEntityType.VEHICLE,
        onChangeSelectedEntities: (
          values: string[],
          items?: IBatchesCheckbox[],
        ) => {
          setSelectedVehicles(items || []);
        },
        selectedValues: selectedVehicles.map(el => `${el.prefix}${el.id}`),
        selectedItems: selectedVehicles,
        label: t(translations.filterBlock.vehicle),
      },
      {
        entityType: NotificationEntityType.DRIVER,
        onChangeSelectedEntities: (
          values: string[],
          items?: IBatchesCheckbox[],
        ) => {
          setSelectedDrivers(items || []);
        },
        selectedValues: selectedDrivers.map(el => `${el.prefix}${el.id}`),
        selectedItems: selectedDrivers,
        label: t(translations.filterBlock.driver),
      },
      {
        entityType: NotificationEntityType.PROJECT,
        onChangeSelectedEntities: (
          values: string[],
          items?: IBatchesCheckbox[],
        ) => {
          setSelectedProjects(items || []);
        },
        selectedValues: selectedProjects.map(el => `${el.prefix}${el.id}`),
        selectedItems: selectedProjects,
        label: t(translations.filterBlock.project),
      },
      {
        entityType: NotificationEntityType.POUR_EVENT,
        onChangeSelectedEntities: (
          values: string[],
          items?: IBatchesCheckbox[],
        ) => {
          setSelectedPourEvents(items || []);
        },
        selectedValues: selectedPourEvents.map(
          el => `${el.prefix}${el.additionalId || el.id}`,
        ),
        selectedItems: selectedPourEvents,
        label: t(translations.filterBlock.pourEvent),
      },
    ];
  }, [
    selectedCustomers,
    selectedDrivers,
    selectedMixDesigns,
    selectedPlants,
    selectedPourEvents,
    selectedProjects,
    selectedVehicles,
    t,
  ]);

  const hasSelectedItems = useMemo(() => {
    return (
      !!selectedCustomers.length ||
      !!selectedDrivers.length ||
      !!selectedMixDesigns.length ||
      !!selectedPlants.length ||
      !!selectedPourEvents.length ||
      !!selectedProjects.length ||
      !!selectedVehicles.length ||
      !!selectedEventStatusCodes.length ||
      !!selectedUsers.length
    );
  }, [
    selectedCustomers.length,
    selectedDrivers.length,
    selectedMixDesigns.length,
    selectedPlants.length,
    selectedPourEvents.length,
    selectedProjects.length,
    selectedVehicles.length,
    selectedEventStatusCodes.length,
    selectedUsers.length,
  ]);

  const renderSelectedItems = useMemo(() => {
    return dropdowns.map(el => {
      return el.selectedItems.map(item => (
        <div
          key={item.id}
          className={`${classes.selectedItem} ${
            item.prefix === '-'
              ? classes.selectedItemNegative
              : classes.selectedItemPositive
          }`}
        >
          <CustomTypography
            variant="caption1"
            color="greyscale1"
            style={{ whiteSpace: 'nowrap' }}
          >
            {`${item.prefix}${item.id}/${item.label}`}
          </CustomTypography>

          <div
            className={classes.closeButton}
            onClick={() => removePlantHandler(item, el.entityType)}
          >
            <CloseIcon height={15} />
          </div>
        </div>
      ));
    });
  }, [
    classes.closeButton,
    classes.selectedItem,
    classes.selectedItemNegative,
    classes.selectedItemPositive,
    dropdowns,
    removePlantHandler,
  ]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.dropdownsContainer}>
          {dropdowns.slice(0, 5).map(el => (
            <EntitiesDropdown
              {...el}
              key={el.entityType}
              selectedEntitiesIds={selectedEntitiesIds}
              isCrossmatching={!isUserAssignedEntities}
            />
          ))}
        </div>
      </div>
      <div className={classes.container}>
        <div className={classes.dropdownsContainer}>
          {dropdowns.slice(5, 7).map(el => (
            <EntitiesDropdown
              {...el}
              key={el.entityType}
              selectedEntitiesIds={selectedEntitiesIds}
              isCrossmatching={!isUserAssignedEntities}
            />
          ))}
          <EventStatusDropdown
            label={t(translations.filterBlock.historyStatus)}
            onChangeSelectedEntities={(_, items) =>
              setSelectedEventStatusCodes(items || [])
            }
            selectedItems={selectedEventStatusCodes}
            selectedValues={selectedEventStatusCodes.map(
              el => `${el.prefix}${el.label}`,
            )}
          />
          <UsersDropdown
            label={t(translations.filterBlock.users)}
            onChangeSelectedEntities={(_, items) =>
              setSelectedUsers(items || [])
            }
            selectedItems={selectedUsers}
            selectedValues={selectedUsers.map(el => `${el.prefix}${el.label}`)}
          />
        </div>
      </div>

      <div className={classes.dateContainer}>
        <div className={classes.datePickerContainer}>
          <DatePicker
            changeDateHandler={changeHandler}
            startDate={startDate ? new Date(startDate) : undefined}
            endDate={endDate ? new Date(endDate) : undefined}
          />
        </div>

        {!isMobile && (
          <div className={classes.applyButton}>
            <Button
              onClick={applyFilterHandler}
              height={42}
              variant={'secondaryLight'}
            >
              <CustomTypography
                variant="buttonTextSmall"
                bold
                color={'mbsBlue'}
              >
                {t(translations.datePicker.apply)}
              </CustomTypography>
            </Button>
          </div>
        )}
      </div>

      <div className={classes.selectedItems}>
        {renderSelectedItems}
        {selectedEventStatusCodes.map(item => (
          <div
            key={item.id}
            className={`${classes.selectedItem} ${
              item.prefix === '-'
                ? classes.selectedItemNegative
                : classes.selectedItemPositive
            }`}
          >
            <CustomTypography
              variant="caption1"
              color="greyscale1"
              style={{ whiteSpace: 'nowrap' }}
            >
              {`${item.prefix}${item.label}`}
            </CustomTypography>

            <div
              className={classes.closeButton}
              onClick={() =>
                setSelectedEventStatusCodes(curr =>
                  curr.filter(i => i.id !== item.id),
                )
              }
            >
              <CloseIcon height={15} />
            </div>
          </div>
        ))}
        {selectedUsers.map(item => (
          <div
            key={item.id}
            className={`${classes.selectedItem} ${
              item.prefix === '-'
                ? classes.selectedItemNegative
                : classes.selectedItemPositive
            }`}
          >
            <CustomTypography
              variant="caption1"
              color="greyscale1"
              style={{ whiteSpace: 'nowrap' }}
            >
              {`${item.prefix}${item.label}`}
            </CustomTypography>

            <div
              className={classes.closeButton}
              onClick={() =>
                setSelectedUsers(curr => curr.filter(i => i.id !== item.id))
              }
            >
              <CloseIcon height={15} />
            </div>
          </div>
        ))}
        {hasSelectedItems && !isMobile ? <>{clearButton}</> : null}
      </div>

      {isMobile && (
        <div className={classes.buttonContainer}>
          {hasSelectedItems ? <>{clearButton}</> : null}

          <Button
            onClick={applyFilterHandler}
            height={42}
            variant={'secondaryLight'}
          >
            <CustomTypography variant="buttonTextSmall" bold color={'mbsBlue'}>
              {t(translations.datePicker.apply)}
            </CustomTypography>
          </Button>
        </div>
      )}
    </div>
  );
};
