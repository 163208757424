import { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import { translations } from 'app/locales/i18n';
import { SectionSpoiler } from 'app/shared/components/generic-ui/SectionSpoiler';
import {
  useBatchApi,
  useBatchHistoryList,
  useOrganizationsApi,
} from 'app/shared/hooks';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useStyles } from './BatchesPage.styles';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { BatchList } from './components';
import { FiltersBlock } from './components/FiltersBlock';
import { CircularProgress } from 'app/shared/components/generic-ui/CircularProgress';
import { IHistoryRecord, IMetaData, Routes } from 'types';
import { Checkbox } from '@material-ui/core';
import { ReactComponent as RadioIcon } from 'assets/radio_icon.svg';
import { ReactComponent as RadioEmpty } from 'assets/radio_empty.svg';
import { useAuth } from 'app/shared/providers';
import { useHistory } from 'react-router-dom';
import {
  CreateCustomFilterModal,
  CustomFilterModal,
} from 'app/shared/components/generic-ui/CustomFilterModal/CustomFilterModal';
import { Button } from 'app/shared/components/generic-ui/Button/Button';
import { getInitialFilters, getSelectedEntitiesIds, setFilters } from './utils';
import { format, startOfDay } from 'date-fns';

const emptyRows = 0;
const skipOnePage = 1;

export const BatchesPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    organizationId,
    page,
    perPage,
  }: { organizationId: string; page: string; perPage: string } = useParams();
  const { isMember, userId } = useAuth();
  const history = useHistory();

  const [batchList, setBatchList] = useState<IHistoryRecord[]>();
  const [totalCount, setTotalCount] = useState<number>(emptyRows);
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [checked, setChecked] = useState(true);
  const [toJobRemaining, setToJobRemaining] = useState(false);
  const [predictions, setPredictions] = useState(false);
  const [isSavedFilterModalOpened, setIsSavedModalFilterOpened] =
    useState(false);
  const [isCreateFilterModalOpened, setIsCreateModalFilterOpened] =
    useState(false);
  const [show, setShow] = useState(false);
  const mounted = useRef(false);

  const [isUserAssignedEntities, setUserAssignedEntities] = useState(isMember);

  const { organizationDetails } = useOrganizationsApi({
    organizationId: organizationId || '',
  });

  const {
    latestUserFilter,
    userFilter,
    isFetchingLatestUserFilter,
    ifFetchingUserFilter,
    fetchLatestUserFilter,
    createUpdateUserFilterPost,
  } = useBatchHistoryList({
    organizationId,
    queryParameters: {
      userId,
    },
  });

  const { fetchMapHeader, mapHeader, isFetchingMapHeader } = useBatchApi({
    organizationId: organizationId,
  });

  useEffect(() => {
    if (
      !isFetchingLatestUserFilter &&
      !ifFetchingUserFilter &&
      mounted.current
    ) {
      if (userFilter?.data || latestUserFilter?.data) {
        //@ts-ignore
        setFilters(userFilter?.data || latestUserFilter?.data);
      }
      setTimeout(() => {
        setShow(true);
      }, 10);
    }
  }, [
    userFilter,
    latestUserFilter,
    isFetchingLatestUserFilter,
    ifFetchingUserFilter,
  ]);

  useEffect(() => {
    if (isFetchingLatestUserFilter || ifFetchingUserFilter) {
      setShow(false);
    }
  }, [ifFetchingUserFilter, isFetchingLatestUserFilter]);

  useEffect(() => {
    fetchMapHeader();
  }, [fetchMapHeader]);

  useEffect(() => {
    fetchLatestUserFilter();
    mounted.current = true;
  }, [fetchLatestUserFilter]);

  const savedFilterModalClick = useCallback(() => {
    setIsSavedModalFilterOpened(true);
  }, []);

  const closeFilterModal = useCallback(() => {
    setIsSavedModalFilterOpened(false);
  }, []);

  const dateFilterFormat = useCallback((date: Date | undefined | null) => {
    return date
      ? format(
          startOfDay(new Date(date.toISOString())),
          "yyyy-MM-dd'T'HH:mm:ssxxx",
        )
      : '';
  }, []);

  const openCreateFilter = useCallback(() => {
    setIsCreateModalFilterOpened(true);
  }, []);

  const closeCreateFilter = useCallback(() => {
    setIsCreateModalFilterOpened(false);
  }, []);

  const creteFilter = useCallback(
    (filterName: string) => {
      const filters = getInitialFilters();
      createUpdateUserFilterPost({
        userId,
        filterName,
        customization: {
          filteredTicketRequest: {
            ...getSelectedEntitiesIds(filters),
            startDateTime: filters.startDate
              ? dateFilterFormat(filters.startDate)
              : '',
            endDateTime: filters.endDate
              ? dateFilterFormat(filters.endDate)
              : !filters.endDate && filters.startDate
              ? dateFilterFormat(filters.startDate)
              : '',
            skip: `${(+page - skipOnePage) * +perPage}`,
            take: perPage,
            ticketsWithStatusesOnly: checked,
          },
          selectedOptions: Array.from(
            {
              length: 8,
            },
            (_, index) => ({
              childOption:
                window.localStorage.getItem(`property${index + 1}`) || '',
              headerOption:
                window.localStorage.getItem(`status${index + 1}`) || '',
            }),
          ),
        },
      });
      closeCreateFilter();
    },
    [
      checked,
      closeCreateFilter,
      createUpdateUserFilterPost,
      dateFilterFormat,
      page,
      perPage,
      userId,
    ],
  );

  const changeCheckboxHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setChecked(event.target.checked);

      history.push({
        pathname: Routes.Batches.replace(
          ':organizationId/:page/:perPage',
          `${organizationId}/1/20`,
        ),
        search: location.search,
      });
    },
    [history, organizationId],
  );

  const checkbox = useMemo(() => {
    return (
      <div className={classes.checkbox}>
        <Checkbox
          checkedIcon={<RadioIcon />}
          icon={<RadioEmpty />}
          onChange={changeCheckboxHandler}
          checked={checked}
        />
        <CustomTypography variant="bodySmall" color="accentDark">
          {t(translations.batchesPage.showBatchesWithStatuses)}
        </CustomTypography>
      </div>
    );
  }, [changeCheckboxHandler, checked, classes.checkbox, t]);

  const refreshHandler = useCallback(() => {
    setRefresh(prevState => !prevState);
  }, []);

  const changeUserAssignedEntitiesHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setUserAssignedEntities(event.target.checked);

      history.push({
        pathname: Routes.Batches.replace(
          ':organizationId/:page/:perPage',
          `${organizationId}/1/20`,
        ),
        search: location.search,
      });
    },
    [history, organizationId],
  );

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <CustomTypography variant="header3" bold color="accentDark">
          {`${organizationDetails?.supplierName || ''} ${t(
            translations.batchesPage.batches,
          )}`}
        </CustomTypography>
        <div className={classes.container}>
          <div className={classes.headerContainer}>
            <Button variant="secondaryProfile" onClick={openCreateFilter}>
              <CustomTypography variant="subtitle2" bold color="white">
                {t(translations.batchesPage.filterModal.createFilter)}
              </CustomTypography>
            </Button>
          </div>
          <div className={classes.headerContainer}>
            <Button variant="primaryProfile" onClick={savedFilterModalClick}>
              <CustomTypography variant="subtitle2" bold color="mbsBlue">
                {t(translations.batchesPage.filterModal.savedFilters)}
              </CustomTypography>
            </Button>
          </div>
          <div className={classes.checkbox}>
            <Checkbox
              checkedIcon={<RadioIcon />}
              icon={<RadioEmpty />}
              onChange={changeUserAssignedEntitiesHandler}
              checked={isUserAssignedEntities}
            />

            <CustomTypography variant="bodySmall" color="accentDark">
              {t(translations.batchesPage.showUserAssignedEntities)}
            </CustomTypography>
          </div>
        </div>
      </div>

      <div>
        {show && (
          <FiltersBlock
            withStatusesOnly={checked}
            loadingHandler={setIsLoading}
            onChangeTableData={(
              data?: IHistoryRecord[],
              metadata?: IMetaData,
            ) => {
              setBatchList(data);
              setTotalCount(metadata?.totalCount || emptyRows);
            }}
            refresh={refresh}
            refreshHandler={refreshHandler}
            isUserAssignedEntities={isUserAssignedEntities}
            toJobRemaining={toJobRemaining}
            predictions={predictions}
          />
        )}

        <SectionSpoiler
          withIcons={false}
          header={t(translations.batchesPage.allBatches)}
          subHeaders={[{ element: checkbox }]}
        >
          {!isFetchingMapHeader && show && mapHeader ? (
            <BatchList
              batchList={batchList}
              isLoading={isLoading}
              rowsTotal={totalCount}
              statuses={mapHeader}
              refreshHandler={refreshHandler}
              setToJobRemaining={setToJobRemaining}
              setPredictions={setPredictions}
            />
          ) : (
            <CircularProgress withLabel />
          )}
        </SectionSpoiler>
      </div>
      {isSavedFilterModalOpened && (
        <CustomFilterModal
          isOpen={isSavedFilterModalOpened}
          closeHandler={closeFilterModal}
        />
      )}

      {isCreateFilterModalOpened && (
        <CreateCustomFilterModal
          isOpen={isCreateFilterModalOpened}
          closeHandler={closeCreateFilter}
          createFilter={creteFilter}
        />
      )}
    </div>
  );
};
