import React, { useMemo, useState } from 'react';
import { IHistoryRecord } from 'types';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { NotificationCategory } from 'types/general';
import { useScreen } from 'app/shared/hooks';
import { themeLayout } from 'app/shared/theme/theme';
import { ChartUnlimitedRange } from 'app/shared/components/generic-ui/Chart';

interface INotificationChartProps {
  historyRecord: IHistoryRecord;
  returnedConcreteMeasurement: string;
}

const zero = 0;
const smallModal = 327;
const bigModal = 513;

export const NotificationChart = ({
  historyRecord,
  returnedConcreteMeasurement,
}: INotificationChartProps) => {
  const { t } = useTranslation();
  const [measurement, setMeasurement] = useState<null | string>(null);
  const { screenWidth } = useScreen();
  const availableWidth =
    screenWidth > themeLayout.mobileWidth ? bigModal : smallModal;

  const isSlumpNotification = useMemo(
    () =>
      [
        NotificationCategory.SLUMP,
        NotificationCategory.SLUMP_PREV_BATCH,
      ].includes(historyRecord.notificationCategory as any),
    [historyRecord],
  );

  const coreValue = useMemo(() => {
    return historyRecord.notificationRangeValue;
  }, [historyRecord.notificationRangeValue]);

  const chartWithSign = useMemo(() => {
    return (
      historyRecord.notificationCategory !== NotificationCategory.TIME_DELAY
    );
  }, [historyRecord.notificationCategory]);

  const value = useMemo(() => {
    switch (historyRecord.notificationCategory) {
      case NotificationCategory.TEMPERATURE: {
        setMeasurement(historyRecord.probeTemperature.measurement);
        return historyRecord.alertReferenceValue;
      }
      case NotificationCategory.TEMP_PREV_BATCH: {
        setMeasurement(historyRecord.probeTemperature.measurement);
        return historyRecord.alertReferenceValue;
      }
      case NotificationCategory.SLUMP: {
        setMeasurement(historyRecord.workability.measurement);
        return historyRecord.alertReferenceValue;
      }
      case NotificationCategory.SLUMP_PREV_BATCH: {
        setMeasurement(historyRecord.workability.measurement);
        return historyRecord.alertReferenceValue;
      }
      case NotificationCategory.TIME_DELAY: {
        setMeasurement(t(translations.units.min));
        return historyRecord.alertReferenceValue;
      }
      case NotificationCategory.REVOLUTIONS: {
        setMeasurement(historyRecord.revolutions.measurement);
        return historyRecord.alertReferenceValue;
      }
      case NotificationCategory.ADDING_WATER:
      case NotificationCategory.WATER_CEMENT_RATIO: {
        setMeasurement(null);
        return historyRecord.alertReferenceValue;
      }
      case NotificationCategory.RETURNED_CONCRETE: {
        setMeasurement(returnedConcreteMeasurement);
        return historyRecord.alertReferenceValue;
      }
      case NotificationCategory.REJECTED_LOAD:
      case NotificationCategory.TARGET_DEVIATION: {
        setMeasurement(t(translations.units.percent));
        return historyRecord.alertReferenceValue;
      }
      case NotificationCategory.MOISTURE: {
        setMeasurement(t(translations.units.percent));
        return historyRecord.alertReferenceValue;
      }
      case NotificationCategory.AIR_CONTENT:
      case NotificationCategory.SURFACE_EVAPORATION_RATE: {
        setMeasurement(t(translations.units.percent));
        return historyRecord.alertReferenceValue;
      }
      default:
        return zero;
    }
  }, [
    historyRecord.alertReferenceValue,
    historyRecord.notificationCategory,
    historyRecord.probeTemperature.measurement,
    historyRecord.revolutions.measurement,
    historyRecord.workability.measurement,
    returnedConcreteMeasurement,
    t,
  ]);

  const chartLabel = useMemo(() => {
    switch (historyRecord.notificationCategory) {
      case NotificationCategory.SLUMP ||
        NotificationCategory.SLUMP_PREV_BATCH: {
        return t(translations.charts.slumpChart);
      }
      case NotificationCategory.TEMPERATURE: {
        return t(translations.charts.temperature);
      }
      case NotificationCategory.TEMPERATURE ||
        NotificationCategory.TEMP_PREV_BATCH: {
        return t(translations.charts.temperature);
      }
      case NotificationCategory.REVOLUTIONS: {
        return t(translations.charts.revolutions);
      }
      case NotificationCategory.ADDING_WATER: {
        return t(translations.charts.trucksAddingWater);
      }
      case NotificationCategory.TARGET_DEVIATION: {
        return t(translations.charts.batchWeightDev);
      }
      case NotificationCategory.RETURNED_CONCRETE: {
        return t(translations.charts.returnedConcrete);
      }
      case NotificationCategory.REJECTED_LOAD: {
        return t(translations.charts.rejectedLoad);
      }
      case NotificationCategory.TIME_DELAY: {
        return t(translations.charts.timeDelay);
      }
      case NotificationCategory.MOISTURE: {
        return t(translations.charts.aggregateMoistureChart);
      }
      case NotificationCategory.SURFACE_EVAPORATION_RATE: {
        return t(translations.charts.surfaceEvaporationChart);
      }
      default:
        return '';
    }
  }, [historyRecord.notificationCategory, t]);

  return (
    <ChartUnlimitedRange
      notificationCategory={historyRecord.notificationCategory}
      chartLabel={chartLabel}
      coreValue={coreValue}
      value={value}
      measurement={measurement}
      iconType={isSlumpNotification ? 'Triangle' : 'Circle'}
      availableWidth={availableWidth}
      chartWithSign={chartWithSign}
    />
  );
};
