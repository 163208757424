import { makeStyles } from '@material-ui/core';
import { themeLayout } from 'app/shared/theme';
import { themeColors } from 'app/shared/theme/theme';

const mid = 460;
const big = 570;
const large = 1380;

export const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: 20,
    backgroundColor: themeColors.white,
    marginTop: 16,
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 15,
    marginRight: 24,
    [theme.breakpoints.down(mid)]: {
      width: 265,
    },
    [theme.breakpoints.between(themeLayout.breakpointSmall, mid)]: {
      width: 425,
    },
    [theme.breakpoints.between(mid, big)]: {
      width: 340,
    },
    [theme.breakpoints.between(big, themeLayout.breakpointLarge)]: {
      width: 425,
    },
    [theme.breakpoints.up(themeLayout.breakpointLarge)]: {
      width: 425,
    },
  },
  formControl: {
    width: '100%',

    height: 44,
  },
  selectContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 260,
    marginBottom: 15,
    marginRight: 24,
  },
  applyButton: {
    marginLeft: 16,
  },
  dateContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'end',
  },
  container: {
    display: 'flex',
    width: '100%',

    [theme.breakpoints.down(large)]: {
      flexDirection: 'column',
      alignItems: 'start',
    },
    [theme.breakpoints.up(large)]: {
      flexDirection: 'row',
      alignItems: 'end',
      justifyContent: 'left',
    },
  },
  dropdownsContainer: {
    display: 'flex',
    width: '100%',

    [theme.breakpoints.down(large)]: {
      flexWrap: 'wrap',
    },
    [theme.breakpoints.up(large)]: {
      flexDirection: 'row',
      alignItems: 'end',
      justifyContent: 'left',
    },
  },
  selectedItem: {
    height: 30,
    padding: '6px 10px ',

    width: 'fit-content',
    borderRadius: 30,
    boxSizing: 'border-box',
    marginRight: 8,
    marginTop: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectedItemPositive: { backgroundColor: themeColors.highlightBlue },
  selectedItemNegative: { backgroundColor: themeColors.highlightRed },
  closeButton: {
    padding: '0px 0px 0px 8px',
    cursor: 'pointer',
  },
  datePickerContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '-8px',
    [theme.breakpoints.down(themeLayout.breakpointBig)]: {
      // marginLeft: '-8px',
      marginTop: 16,
    },
    [theme.breakpoints.down(mid)]: {
      width: 265,
    },
    [theme.breakpoints.between(
      themeLayout.breakpointSmall,
      themeLayout.breakpointBig,
    )]: {
      width: 440,
    },
    [theme.breakpoints.between(mid, big)]: {
      width: 340,
    },
    [theme.breakpoints.up(themeLayout.breakpointBig)]: {
      width: 440,
    },
  },
  selectedItems: {
    flexDirection: 'row',
    display: 'flex',
    width: '100%',
    flexFlow: 'wrap',
  },
  clearAll: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 10,
  },
  close: {
    marginLeft: 6,
    width: 12,
    height: 12,
    border: `1px solid ${themeColors.mbsBlue}`,
    borderRadius: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonContainer: {
    marginTop: 15,
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down(mid)]: {
      width: 250,
    },
    [theme.breakpoints.between(
      themeLayout.breakpointSmall,
      themeLayout.breakpointBig,
    )]: {
      width: 424,
    },
    [theme.breakpoints.between(mid, big)]: {
      width: 324,
    },
    [theme.breakpoints.up(themeLayout.breakpointBig)]: {
      width: 324,
    },
  },
  root: {
    height: '44px',
  },
  inputRoot: {
    height: '38px !important',
    padding: 0,
  },
  input: {
    padding: 0,
    marginTop: '-8px',
  },
  defaultMenu: {
    marginTop: 42,
  },
  checkedIcon: {
    color: `${themeColors.mbsBlue} !important`,
  },
  checkbox: {
    padding: '5px 14px 5px 0px',
  },
  menuItem: {
    backgroundColor: `${themeColors.white} !important`,
    '&:hover': {
      backgroundColor: `${themeColors.highlightBlue} !important`,
    },
    '&:focus': {
      backgroundColor: `${themeColors.highlightBlue} !important`,
    },
    '&.Mui-active': {
      backgroundColor: `${themeColors.highlightBlue} !important`,
    },
  },
  icon: {
    color: themeColors.greyscale,
    '&:hover': {
      backgroundColor: `${themeColors.highlightBlue} !important`,
    },
  },
}));
