import React, { useMemo } from 'react';
import { useStyles } from './Button.styles';
import { ReactComponent as ImageProjects } from 'assets/grid_icon.svg';
import { ReactComponent as ImageTeammates } from 'assets/users.svg';
import { ReactComponent as ImageProfile } from 'assets/profile_icon.svg';
import { ReactComponent as ImageMyOrganization } from 'assets/my_organization_icon.svg';
import { ReactComponent as ImageAllOrganizations } from 'assets/all_organization_icon.svg';
import { ReactComponent as ImageLogOut } from 'assets/log_out_icon.svg';
import { ReactComponent as FilterIcon } from 'assets/filter.svg';
import { ReactComponent as ImageBatches } from 'assets/batches_icon.svg';
import { ReactComponent as ImageReport } from 'assets/file_text.svg';
import { ReactComponent as ImageNotifications } from 'assets/settings.svg';

import { themeColors } from 'app/shared/theme/theme';
import { CircularProgress, ProgressType } from '../CircularProgress';

export interface IButton {
  variant:
    | 'primary'
    | 'secondaryDark'
    | 'secondaryLight'
    | 'pagination'
    | 'header'
    | 'headerActive'
    | 'secondaryBlack'
    | 'primaryNotFound'
    | 'primaryProfile'
    | 'view'
    | 'activeView'
    | 'primarySave'
    | 'secondaryOutlined'
    | 'secondarySave'
    | 'primaryCancel'
    | 'primaryOutlined'
    | 'primaryBig'
    | 'link'
    | 'clearAll'
    | 'filter'
    | 'filterSmall'
    | 'filterActive'
    | 'filterSmallActive'
    | 'clickHere'
    | 'secondaryCancel'
    | 'secondaryProfile';
  children?: string | JSX.Element;
  onClick?: () => void;
  disabled?: boolean;
  active?: boolean;
  icon?:
    | 'projects'
    | 'teammates'
    | 'profile'
    | 'myOrganization'
    | 'allOrganizations'
    | 'allNotifications'
    | 'logOut'
    | 'filter'
    | 'batches'
    | 'reports';
  onHover?: (value: boolean) => void;
  type?: 'button' | 'reset' | 'submit';
  height?: number;
  isLoading?: boolean;
  className?: string;
}

export const Button = ({
  variant,
  children,
  onClick,
  disabled,
  active,
  icon,
  onHover,
  type,
  height,
  isLoading,
  className,
}: IButton) => {
  const classes = useStyles();

  const getIcon = useMemo(() => {
    if (icon === 'projects' && active !== undefined) {
      return (
        <ImageProjects
          stroke={active ? themeColors.mbsBlue : themeColors.greyscale1}
          className={classes.icon}
        />
      );
    } else if (icon === 'teammates' && active !== undefined) {
      return (
        <ImageTeammates
          stroke={active ? themeColors.mbsBlue : themeColors.greyscale1}
          className={classes.icon}
        />
      );
    } else if (icon === 'allNotifications' && active !== undefined) {
      return (
        <ImageNotifications
          stroke={active ? themeColors.mbsBlue : themeColors.greyscale1}
          className={classes.icon}
          height={20}
        />
      );
    } else if (icon === 'batches' && active !== undefined) {
      return (
        <ImageBatches
          stroke={active ? themeColors.mbsBlue : themeColors.greyscale1}
          className={classes.icon}
        />
      );
    } else if (icon === 'profile') {
      return <ImageProfile className={classes.icon} />;
    } else if (icon === 'myOrganization') {
      return <ImageMyOrganization className={classes.icon} />;
    } else if (icon === 'allOrganizations') {
      return <ImageAllOrganizations className={classes.icon} />;
    } else if (icon === 'reports') {
      return (
        <ImageReport
          className={classes.icon}
          stroke={active ? themeColors.mbsBlue : themeColors.greyscale1}
          height={20}
        />
      );
    } else if (icon === 'filter' && active !== undefined) {
      return (
        <FilterIcon
          height={14}
          width={14}
          strokeWidth={0.3}
          stroke={active ? themeColors.white : themeColors.mbsBlue}
          fill={active ? themeColors.white : themeColors.mbsBlue}
        />
      );
    } else if (icon === 'logOut') {
      return <ImageLogOut className={classes.icon} />;
    }
  }, [active, classes.icon, icon]);

  return (
    <button
      type={type}
      onMouseEnter={() => {
        onHover && onHover(true);
      }}
      onMouseLeave={() => {
        onHover && onHover(false);
      }}
      onClick={onClick}
      disabled={disabled}
      className={`${classes[variant]} ${className}`}
      style={{ height: height || '' }}
    >
      {icon && getIcon}
      {isLoading ? <CircularProgress type={ProgressType.LIGHT} /> : children}
    </button>
  );
};
