import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  IResponseData,
  IError,
  IApiParams,
  IHistoryRecord,
  IRelatedEntitiesPayload,
  ICreateUpdateUserPayload,
  IDeleteUserFilterPayload,
} from 'types';
import { useAuth, useError } from '../providers';
import { useAuthClient } from './useAuthClient';
import { firebaseAnalytics } from '../analytics';
import { errors } from '../config';

const batchHistoryListQueryKey = 'getPourEvents';
const getLatestUserFilterQueryKey = 'getLatestUserFilter';
const getUserFilterQueryKey = 'getUserFilter';
const getUserFiltersQueryKey = 'getUserFilters';

export function useBatchHistoryList({
  queryParameters,
  organizationId,
}: IApiParams) {
  const authClient = useAuthClient();
  const { openModal } = useError();
  const { supplierId } = useAuth();
  const queryClient = useQueryClient();

  const currentSupplierId = useMemo(() => {
    return organizationId || supplierId;
  }, [organizationId, supplierId]);

  const getBatchHistoryListUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/BatchHistoryList/get-filtered-tickets`;
  }, [currentSupplierId]);

  const getTicketHistoryUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/BatchHistoryList/get-ticket-history`;
  }, [currentSupplierId]);

  const createUpdateUserFilter = useMemo(() => {
    return `/api/v1/${currentSupplierId}/BatchHistoryList/create-update-user-filter`;
  }, [currentSupplierId]);

  const getLatestUserFilter = useMemo(() => {
    return `/api/v1/${currentSupplierId}/BatchHistoryList/get-latest-user-filter`;
  }, [currentSupplierId]);

  const getUserFilter = useMemo(() => {
    return `/api/v1/${currentSupplierId}/BatchHistoryList/get-user-filter`;
  }, [currentSupplierId]);

  const getUserFilters = useMemo(() => {
    return `/api/v1/${currentSupplierId}/BatchHistoryList/get-user-filters`;
  }, [currentSupplierId]);

  const deleteUserFilterURL = useMemo(() => {
    return `/api/v1/${currentSupplierId}/BatchHistoryList/delete-user-filter`;
  }, [currentSupplierId]);

  const url = useMemo(() => {
    return queryParameters &&
      !Object.keys(queryParameters).includes('ticketNumber')
      ? getBatchHistoryListUrl
      : getTicketHistoryUrl;
  }, [getBatchHistoryListUrl, getTicketHistoryUrl, queryParameters]);

  const {
    data: latestUserFilter,
    refetch: fetchLatestUserFilter,
    isFetching: isFetchingLatestUserFilter,
    error: latestUserFilterError,
  } = useQuery<IResponseData<ICreateUpdateUserPayload>, IError>(
    [getLatestUserFilterQueryKey],
    async () => {
      return await authClient(getLatestUserFilter, {
        queryParameters,
        method: 'GET',
      });
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: error => {
        openModal(error);
      },
    },
  );

  const {
    data: userFilter,
    refetch: fetchUserFilter,
    isFetching: ifFetchingUserFilter,
    error: userFilterError,
  } = useQuery<IResponseData<ICreateUpdateUserPayload>, IError>(
    [getUserFilterQueryKey],
    async () => {
      return await authClient(getUserFilter, {
        queryParameters,
        method: 'GET',
      });
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      onError: error => {
        openModal(error);
      },
    },
  );

  const {
    data: userFilters,
    refetch: fetchUserFilters,
    isFetching: ifFetchingUserFilters,
    error: userFilterErrors,
  } = useQuery<IResponseData<ICreateUpdateUserPayload[]>, IError>(
    [getUserFiltersQueryKey],
    async () => {
      return await authClient(getUserFilters, {
        queryParameters,
        method: 'GET',
      });
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: error => {
        openModal(error);
      },
    },
  );

  const {
    data: createUpdateUserFilterResponse,
    mutateAsync: createUpdateUserFilterPost,
    isLoading: isLoadingCreateUpdateUserFilter,
  } = useMutation(
    (payload: ICreateUpdateUserPayload) =>
      authClient(createUpdateUserFilter, {
        method: 'POST',
        data: payload,
      }),
    {
      retry: false,
      onMutate: async () => {
        await queryClient.cancelQueries(getUserFiltersQueryKey);
        return queryClient.getQueryData<IResponseData<any>>(
          getUserFiltersQueryKey,
        );
      },
      onError: (error: IError) => {
        openModal(error);
      },
      onSettled: () => {
        queryClient.invalidateQueries(getUserFiltersQueryKey);
        queryClient.refetchQueries(getUserFiltersQueryKey);
      },
    },
  );

  const {
    data: deleteUserFilterRes,
    mutateAsync: deleteUserFilter,
    isLoading: isDeletingUserFilter,
  } = useMutation(
    (payload: IDeleteUserFilterPayload) =>
      authClient(deleteUserFilterURL, {
        method: 'DELETE',
        //@ts-ignore
        queryParameters: payload,
      }),
    {
      retry: false,
      onMutate: async () => {
        await queryClient.cancelQueries(getUserFiltersQueryKey);
        return queryClient.getQueryData<IResponseData<any>>(
          getUserFiltersQueryKey,
        );
      },
      onError: (error: IError) => {
        openModal(error);
      },
      onSettled: () => {
        queryClient.invalidateQueries(getUserFiltersQueryKey);
        queryClient.refetchQueries(getUserFiltersQueryKey);
      },
    },
  );

  const {
    data: batchesHistoryResponse,
    mutateAsync: fetchBatchesHistory,
    isLoading: isFetchingBatchesHistory,
  } = useMutation(
    (payload: IRelatedEntitiesPayload) =>
      authClient(getBatchHistoryListUrl, {
        method: 'POST',
        data: payload,
      }),
    {
      retry: false,
      onMutate: async () => {
        await queryClient.cancelQueries(batchHistoryListQueryKey);
        return queryClient.getQueryData<IResponseData<any>>(
          batchHistoryListQueryKey,
        );
      },
      onError: (error: IError, variables, context) => {
        if (context) {
          queryClient.setQueryData<IResponseData<any>>(
            batchHistoryListQueryKey,
            context,
          );
        }

        firebaseAnalytics.logErrorEvent(errors.getBatchHistoryList, error, {
          batchHistoryListQueryKey,
          variables: JSON.stringify(variables),
        });
        openModal(error);
      },
      onSettled: () => {
        queryClient.invalidateQueries(batchHistoryListQueryKey);
      },
    },
  );

  const {
    data: ticketHistoryResponse,
    refetch: fetchTicketHistory,
    isFetching: isFetchingTicketHistory,
    error: fetchTicketHistoryError,
  } = useQuery<IResponseData<IHistoryRecord[]>, IError>(
    [batchHistoryListQueryKey, { queryParameters }],
    async () => {
      return await authClient(getTicketHistoryUrl, {
        queryParameters,
        method: 'GET',
      });
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: error => {
        firebaseAnalytics.logErrorEvent(errors.getPourEventHistory, error, {
          url,
          ...queryParameters,
        });
        openModal(error);
      },
    },
  );

  const batchesHistory = useMemo(() => {
    return queryParameters?.ticketNumber
      ? ticketHistoryResponse?.data
      : batchesHistoryResponse?.data;
  }, [
    batchesHistoryResponse?.data,
    queryParameters?.ticketNumber,
    ticketHistoryResponse?.data,
  ]);

  const batchesHistoryMetadata = useMemo(() => {
    return queryParameters?.ticketNumber
      ? ticketHistoryResponse?.metadata
      : batchesHistoryResponse?.metadata;
  }, [
    batchesHistoryResponse?.metadata,
    queryParameters?.ticketNumber,
    ticketHistoryResponse?.metadata,
  ]);

  return {
    fetchTicketHistory,
    isFetchingTicketHistory,
    fetchTicketHistoryError,
    batchesHistory,
    fetchBatchesHistory,
    isFetchingBatchesHistory,
    batchesHistoryMetadata,
    createUpdateUserFilterResponse,
    isLoadingCreateUpdateUserFilter,
    createUpdateUserFilterPost,
    latestUserFilter,
    fetchLatestUserFilter,
    isFetchingLatestUserFilter,
    latestUserFilterError,
    userFilter,
    fetchUserFilter,
    ifFetchingUserFilter,
    userFilterError,
    userFilters,
    fetchUserFilters,
    ifFetchingUserFilters,
    userFilterErrors,
    deleteUserFilterRes,
    deleteUserFilter,
    isDeletingUserFilter,
  };
}
