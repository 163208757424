import { PopoverSize, Select } from 'app/shared/components/generic-ui';
import {
  CellAlignment,
  CellSize,
} from 'app/shared/components/generic-ui/Table/Table.types';
import { useCallback, useEffect, useState } from 'react';
import { useStyles } from './EntitiesDropdown.styles';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { IBatchesCheckbox, IProfile } from 'types';
import { useTeammatesApi } from 'app/shared/hooks/useTeammatesApi';
import { useParams } from 'react-router-dom';

interface IEntitiesDropdownProps {
  label: string;
  selectedValues?: string[];
  selectedItems?: IBatchesCheckbox[];
  onChangeSelectedEntities: (
    values: string[],
    items?: IBatchesCheckbox[],
  ) => void;
}

export const UsersDropdown = ({
  label,
  selectedItems,
  selectedValues,
  onChangeSelectedEntities,
}: IEntitiesDropdownProps) => {
  const classes = useStyles();
  const [searchQuery, setSearchQuery] = useState('');
  const [data, setData] = useState<{ key: string; value: string }[]>([]);
  const { organizationId }: { organizationId: string } = useParams();

  const columns = [
    {
      id: 'value',
      label: 'Name',
      alignment: CellAlignment.LEFT,
      size: CellSize.MEDIUM,
    },
  ];

  const { teammatesResponse, fetchTeammates, isFetchingTeammates } =
    useTeammatesApi({
      organizationId,
      queryParameters: {
        HasActiveDevices: true,
      },
    });

  const renderCell = useCallback((rowData: any, cellId: string) => {
    return <div style={{ whiteSpace: 'nowrap' }}>{rowData[cellId]}</div>;
  }, []);

  const mapData = useCallback(
    (users: IProfile[]) =>
      users.map(val => ({
        value: `${val.firstName || ''} ${val.lastName || ''}`,
        key: val.userId,
      })),
    [],
  );

  useEffect(() => {
    fetchTeammates().then(res => {
      if (res.data) {
        setData(mapData(res.data.data));
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchTeammates]);

  const changeSelectedValuesHandler = useCallback(
    values => {
      const currentItems = values.map((idWithPrefix: string) => {
        const prefix = idWithPrefix[0];
        const id = idWithPrefix.slice(1);
        const element = mapData(teammatesResponse?.data || []).find(
          el => el.value === id,
        );
        const selectedElement = selectedItems?.find(el => el.id === id);

        return element
          ? {
              id: element?.key || '',
              label: element?.value || '',
              prefix,
            }
          : { id: '', label: '', ...selectedElement, prefix };
      });

      onChangeSelectedEntities(values, currentItems);
    },
    [onChangeSelectedEntities, mapData, teammatesResponse?.data, selectedItems],
  );

  return (
    <div className={classes.selectContainer}>
      <CustomTypography variant="caption1" color="greyscale1">
        {label}
      </CustomTypography>

      <Select.Search
        searchQuery={searchQuery}
        isLoading={isFetchingTeammates}
        valueId={'value'}
        labelId={'key'}
        options={data}
        selectedValues={selectedValues}
        selectedItems={selectedItems}
        onChangeSelectedValues={changeSelectedValuesHandler}
        onSearchHandler={value => {
          setSearchQuery(value);
          if (teammatesResponse?.data) {
            setData(
              mapData(teammatesResponse.data).filter(el =>
                el.key.toLowerCase().includes(value.toLowerCase()),
              ),
            );
          }
        }}
        popoverSize={PopoverSize.BIG}
        renderCell={renderCell}
        columns={columns}
        clickHandler={fetchTeammates}
      />
    </div>
  );
};
