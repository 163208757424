import { Modal } from '@material-ui/core';
import { ModalHeader, ModalVariant } from '../ModalHeader';
import { CustomTypography } from '../Typography/Typography';
import { Search } from 'app/shared/components/generic-ui/Search/Search';
import { useTranslation } from 'react-i18next';
import { useStyles } from './CustomFilterModal.styles';
import { translations } from 'app/locales/i18n';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useBatchHistoryList, useScreen } from 'app/shared/hooks';
import { CellAlignment, CellSize, TableHeaderType } from '../Table/Table.types';
import { Table } from 'app/shared/components/generic-ui/Table/Table';
import { useAuth } from 'app/shared/providers';
import { useParams } from 'react-router-dom';
import { Button } from '../Button/Button';

interface IProps {
  isOpen: boolean;
  closeHandler: () => void;
}

const maxModalHeight = 708;
const footerAndHeaderHeight = 159;
const searchContainerHeight = 90;
const paddingOfTableContainer = 25;
const margin = 24;
const emptyLines = 4;
const rowHeight = 44;
const loadingHeight = 170;

export function CustomFilterModal({
  isOpen,

  closeHandler,
}: IProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { screenHeight } = useScreen();
  const { userId } = useAuth();
  const [filterName, setFilterName] = useState('');
  const [search, setSearch] = useState('');
  const { organizationId }: { organizationId: string } = useParams();
  const {
    fetchUserFilter,
    userFilters,
    fetchUserFilters,
    ifFetchingUserFilters,
    deleteUserFilter,
  } = useBatchHistoryList({
    organizationId,
    queryParameters: {
      userId,
      filterName,
    },
  });

  const genericElementsHeight = useMemo(() => {
    return (
      footerAndHeaderHeight +
      searchContainerHeight +
      paddingOfTableContainer +
      margin
    );
  }, []);

  useEffect(() => {
    fetchUserFilters();
  }, [fetchUserFilters]);

  const selectFilter = useCallback((name: string) => {
    setFilterName(name);
  }, []);

  const deleteFilter = useCallback(
    (name: string) => {
      deleteUserFilter({
        filterName: name,
        userId,
      });
    },
    [deleteUserFilter, userId],
  );

  useEffect(() => {
    if (filterName) {
      fetchUserFilter().then(() => {
        closeHandler();
      });
    }
  }, [closeHandler, fetchUserFilter, filterName]);

  const rowsHeight = useMemo(() => {
    return ifFetchingUserFilters
      ? loadingHeight
      : (userFilters?.data?.length ? userFilters?.data.length : emptyLines) *
          rowHeight;
  }, [ifFetchingUserFilters, userFilters?.data?.length]);

  const containerHeight = useMemo(() => {
    return genericElementsHeight + rowsHeight > maxModalHeight
      ? maxModalHeight
      : genericElementsHeight + rowsHeight;
  }, [genericElementsHeight, rowsHeight]);

  const containerStyles = useMemo<React.CSSProperties>(() => {
    return {
      height: containerHeight < screenHeight ? containerHeight : screenHeight,
    };
  }, [containerHeight, screenHeight]);

  const filterItemsColumns = useMemo(() => {
    return [
      {
        id: 'filterName',
        label: t(translations.dosageModal.name),
        alignment: CellAlignment.LEFT,
        size: CellSize.MEDIUM,
        sortable: false,
      },
      {
        id: 'select',
        label: '',
        alignment: CellAlignment.RIGHT,
        size: CellSize.SMALL,
        sortable: false,
      },
      {
        id: 'delete',
        label: '',
        alignment: CellAlignment.RIGHT,
        size: CellSize.SMALL,
        sortable: false,
      },
    ];
  }, [t]);

  const renderFilterItemPropertyCell = useCallback(
    (rowData: any, cellId: string, index: number) => {
      return (
        <div className={classes.cellEntity}>
          {cellId === 'filterName' && (
            <div className={classes.withoutPadding}>
              {rowData[cellId] || '-'}
            </div>
          )}
          {cellId === 'select' && (
            <Button
              variant="secondaryProfile"
              onClick={() => {
                selectFilter(rowData.filterName);
              }}
            >
              <CustomTypography variant="subtitle2" bold color="white">
                {t(translations.batchesPage.filterModal.selectFilter)}
              </CustomTypography>
            </Button>
          )}
          {cellId === 'delete' && (
            <Button
              variant="primaryProfile"
              onClick={() => deleteFilter(rowData.filterName)}
            >
              <CustomTypography variant="subtitle2" bold color="mbsBlue">
                {t(translations.batchesPage.filterModal.deleteFilter)}
              </CustomTypography>
            </Button>
          )}
        </div>
      );
    },
    [classes.cellEntity, classes.withoutPadding, deleteFilter, selectFilter, t],
  );

  return (
    <Modal
      open={isOpen}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          return false;
        }
        closeHandler();
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modal}
    >
      <div className={classes.container} style={containerStyles}>
        <ModalHeader
          className={classes.header}
          variant={ModalVariant.SMALL}
          header={t(translations.batchesPage.filterModal.title)}
          closeHandler={closeHandler}
        />

        <div className={classes.searchWrapper}>
          <div className={classes.subheader}>
            <CustomTypography variant="header6" bold color="accentDark">
              {t(translations.batchesPage.filterModal.searchTitle)}
            </CustomTypography>
          </div>

          <div className={classes.searchContainer}>
            <Search
              placeholder={t(translations.batchesPage.filterModal.searchByName)}
              globalSearch={false}
              onChange={setSearch}
            />
          </div>
        </div>
        <div className={classes.body} style={{ position: 'unset' }}>
          <Table
            columns={filterItemsColumns}
            emptyTableText={t(
              translations.batchesPage.filterModal.noFilterItems,
            )}
            minHeight={300}
            tableData={userFilters?.data?.filter(item =>
              item.filterName.toLowerCase().includes(search.toLowerCase()),
            )}
            withCheckbox={false}
            rowsTotal={
              userFilters?.data?.filter(item =>
                item.filterName.toLowerCase().includes(search.toLowerCase()),
              ).length
            }
            renderCellWithIndexOfRow={renderFilterItemPropertyCell}
            headerType={TableHeaderType.DARK}
            withBorders={false}
            rowId={'filterName'}
            isLoading={ifFetchingUserFilters}
            isScrollable={false}
            overflow="visible"
          />
        </div>
      </div>
    </Modal>
  );
}

interface IPropsCreate {
  isOpen: boolean;
  closeHandler: () => void;
  createFilter: (name: string) => void;
}

export function CreateCustomFilterModal({
  closeHandler,
  isOpen,
  createFilter,
}: IPropsCreate) {
  const [search, setSearch] = useState('');
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Modal
      open={isOpen}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          return false;
        }
        closeHandler();
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modal}
    >
      <div
        className={classes.container}
        style={{ height: 250, display: 'flex', flexDirection: 'column' }}
      >
        <ModalHeader
          variant={ModalVariant.SMALL}
          header={t(translations.batchesPage.filterModal.createFilter)}
          closeHandler={closeHandler}
        />

        <div
          className={classes.searchWrapper}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            flex: 1,
            marginTop: 0,
          }}
        >
          <div className={classes.searchContainer} style={{ borderWidth: 0 }}>
            <Search
              placeholder={t(translations.batchesPage.filterModal.placeholder)}
              globalSearch={false}
              onChange={setSearch}
              hideButton
            />
          </div>
          <div className={classes.headerWrapper}>
            <div className={classes.headerContainer}>
              <Button
                variant="secondaryProfile"
                onClick={() => createFilter(search)}
              >
                <CustomTypography variant="subtitle2" bold color="white">
                  {t(translations.batchesPage.filterModal.create)}
                </CustomTypography>
              </Button>
            </div>
            <div className={classes.headerContainer}>
              <Button variant="primaryProfile" onClick={closeHandler}>
                <CustomTypography variant="subtitle2" bold color="mbsBlue">
                  {t(translations.batchesPage.filterModal.cancel)}
                </CustomTypography>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
