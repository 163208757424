import { useMemo } from 'react';
import { translations } from 'app/locales/i18n';
import {
  CellAlignment,
  CellSize,
} from 'app/shared/components/generic-ui/Table/Table.types';
import { useTranslation } from 'react-i18next';
import { ReactComponent as RefreshIcon } from 'assets/refresh.svg';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';

export function mapDropdowns(values: string[]) {
  return values.map(key => ({
    label: key
      .match(/[A-Z]?[a-z]+|[0-9]+|[A-Z]+(?![a-z])/g)
      ?.join(' ')
      .toUpperCase(),
    value: key,
  }));
}

export const useBatchHistoryTableConfig = (
  onButtonClick: () => void,
  statuses: Record<string, string[]>,
) => {
  const { t } = useTranslation();

  const button = useMemo(() => {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}>
        <RefreshIcon style={{ marginRight: 6 }} />
        <CustomTypography variant="subtitle2" bold color="mbsBlue">
          {t(translations.pourEventPage.refresh)}
        </CustomTypography>
      </div>
    );
  }, [t]);

  const keys = useMemo(() => Object.keys(statuses), [statuses]);

  const defaultTableColumns = useMemo(() => {
    const firstKey =
      keys.find(key => key === localStorage.getItem('status1')) || keys[0];
    const secondKey =
      keys.find(key => key === localStorage.getItem('status2')) || keys[1];
    const thirdKey =
      keys.find(key => key === localStorage.getItem('status3')) || keys[2];
    const fourthKey =
      keys.find(key => key === localStorage.getItem('status4')) || keys[3];
    const fifthKey =
      keys.find(key => key === localStorage.getItem('status5')) || keys[4];
    const sixthKey =
      keys.find(key => key === localStorage.getItem('status6')) || keys[5];
    const seventhKey =
      keys.find(key => key === localStorage.getItem('status7')) || keys[6];
    const eightKey =
      keys.find(key => key === localStorage.getItem('status8')) || keys[7];
    const dropDownValues = mapDropdowns(keys);

    return [
      {
        id: 'empty',
        label: '',
        alignment: CellAlignment.LEFT,
        size: CellSize.MEDIUM,
        rowNumber: 1,
        colSpan: 2,
        buttons: [
          {
            button: button,
            onClick: onButtonClick,
          },
        ],
      },
      {
        id: 'status1',
        label: firstKey,
        alignment: CellAlignment.CENTER,
        size: CellSize.MEDIUM,
        rowNumber: 1,
        dropDownValues: dropDownValues,
      },
      {
        id: 'status2',
        label: secondKey,
        alignment: CellAlignment.CENTER,
        size: CellSize.MEDIUM,
        rowNumber: 1,
        dropDownValues: dropDownValues,
      },
      {
        id: 'status3',
        label: thirdKey,
        alignment: CellAlignment.CENTER,
        size: CellSize.MEDIUM,
        rowNumber: 1,
        dropDownValues: dropDownValues,
      },
      {
        id: 'status4',
        label: fourthKey,
        alignment: CellAlignment.CENTER,
        size: CellSize.MEDIUM,
        rowNumber: 1,
        dropDownValues: dropDownValues,
      },
      {
        id: 'status5',
        label: fifthKey,
        alignment: CellAlignment.CENTER,
        size: CellSize.MEDIUM,
        rowNumber: 1,
        dropDownValues: dropDownValues,
      },
      {
        id: 'status6',
        label: sixthKey,
        alignment: CellAlignment.CENTER,
        size: CellSize.MEDIUM,
        rowNumber: 1,
        dropDownValues: dropDownValues,
      },
      {
        id: 'status7',
        label: seventhKey,
        alignment: CellAlignment.CENTER,
        size: CellSize.MEDIUM,
        rowNumber: 1,
        dropDownValues: dropDownValues,
      },
      {
        id: 'status8',
        label: eightKey,
        alignment: CellAlignment.CENTER,
        size: CellSize.MEDIUM,
        rowNumber: 1,
        dropDownValues: dropDownValues,
      },
      {
        id: 'actions',
        label: '',
        alignment: CellAlignment.LEFT,
        size: CellSize.SMALL,
        rowNumber: 1,
        colSpan: 1,
      },
      {
        id: 'ticketNumber',
        label: t(translations.batchesPage.ticketId),
        alignment: CellAlignment.RIGHT,
        size: CellSize.MEDIUM,
        rowNumber: 2,
        colSpan: 1,
      },

      {
        id: 'dispatchDateTimeUtc',
        label: t(translations.batchesPage.dispatchDate),
        alignment: CellAlignment.LEFT,
        size: CellSize.MEDIUM,
        rowNumber: 2,
        colSpan: 1,
      },

      {
        id: 'property1',
        label:
          statuses[firstKey].find(
            key => key === localStorage.getItem('property1'),
          ) || statuses[firstKey][0],
        alignment: CellAlignment.CENTER,
        size: CellSize.MEDIUM,
        rowNumber: 2,
        colSpan: 1,
        dropDownValues: mapDropdowns(statuses[firstKey]),
      },
      {
        id: 'property2',
        label:
          statuses[secondKey].find(
            key => key === localStorage.getItem('property2'),
          ) || statuses[secondKey][1],
        alignment: CellAlignment.CENTER,
        size: CellSize.MEDIUM,
        rowNumber: 2,
        colSpan: 1,
        dropDownValues: mapDropdowns(statuses[secondKey]),
      },
      {
        id: 'property3',
        label:
          statuses[thirdKey].find(
            key => key === localStorage.getItem('property3'),
          ) || statuses[thirdKey][2],
        alignment: CellAlignment.CENTER,
        size: CellSize.MEDIUM,
        rowNumber: 2,
        colSpan: 1,
        dropDownValues: mapDropdowns(statuses[thirdKey]),
      },
      {
        id: 'property4',
        label:
          statuses[fourthKey].find(
            key => key === localStorage.getItem('property4'),
          ) || statuses[fourthKey][3],
        alignment: CellAlignment.CENTER,
        size: CellSize.MEDIUM,
        rowNumber: 2,
        colSpan: 1,
        dropDownValues: mapDropdowns(statuses[fourthKey]),
      },
      {
        id: 'property5',
        label:
          statuses[fifthKey].find(
            key => key === localStorage.getItem('property5'),
          ) || statuses[fifthKey][4],
        alignment: CellAlignment.CENTER,
        size: CellSize.MEDIUM,
        rowNumber: 2,
        colSpan: 1,
        dropDownValues: mapDropdowns(statuses[fifthKey]),
      },
      {
        id: 'property6',
        label:
          statuses[sixthKey].find(
            key => key === localStorage.getItem('property6'),
          ) || statuses[sixthKey][5],
        alignment: CellAlignment.CENTER,
        size: CellSize.MEDIUM,
        rowNumber: 2,
        colSpan: 1,
        dropDownValues: mapDropdowns(statuses[sixthKey]),
      },
      {
        id: 'property7',
        label:
          statuses[seventhKey].find(
            key => key === localStorage.getItem('property7'),
          ) || statuses[seventhKey][6],
        alignment: CellAlignment.CENTER,
        size: CellSize.MEDIUM,
        rowNumber: 2,
        colSpan: 1,
        dropDownValues: mapDropdowns(statuses[seventhKey]),
      },
      {
        id: 'property8',
        label:
          statuses[eightKey].find(
            key => key === localStorage.getItem('property8'),
          ) || statuses[eightKey][7],
        alignment: CellAlignment.CENTER,
        size: CellSize.MEDIUM,
        rowNumber: 2,
        colSpan: 1,
        dropDownValues: mapDropdowns(statuses[eightKey]),
      },
      {
        id: 'actions',
        label: '',
        alignment: CellAlignment.LEFT,
        size: CellSize.SMALL,
        rowNumber: 2,
        colSpan: 1,
      },
    ];
  }, [button, keys, onButtonClick, statuses, t]);

  return {
    defaultColumns: defaultTableColumns,
  };
};
