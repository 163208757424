import { makeStyles } from '@material-ui/core';
import { theme, themeColors, themeLayout } from 'app/shared/theme';

export const useStyles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: themeColors.accentDark60,
    overflowY: 'scroll',
    boxSizing: 'border-box',
    position: 'relative',
  },
  container: {
    margin: 'auto',
    [theme.breakpoints.down(themeLayout.breakpointBig)]: {
      width: 500,
    },

    [theme.breakpoints.down(themeLayout.breakpointSmall)]: {
      width: '100%',
    },
    outline: 'none !important',
    position: 'relative',
    backgroundColor: themeColors.white,
  },
  header: {
    width: '800px',
  },
  searchWrapper: {
    height: 78,
    marginTop: 10,
    width: '100%',
    boxSizing: 'border-box',
    padding: '0px 24px',
  },
  subheader: {
    padding: '0px 0px 0px 0px',
    width: '100%',
  },
  searchContainer: {
    boxSizing: 'border-box',
    alignSelf: 'center',
    padding: '14px 0px 20px 0px',
    width: '100%',
    borderBottom: `2px solid ${themeColors.greyscale4}`,
  },
  body: {
    position: 'absolute',
    width: 800,
    bottom: 86,
    top: 138,
    boxSizing: 'border-box',
    margin: '12px 0px',

    [theme.breakpoints.down(themeLayout.breakpointBig)]: {
      width: 500,
    },

    [theme.breakpoints.down(themeLayout.breakpointSmall)]: {
      width: '100%',
    },

    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '5px',
      height: '7px',
      backgroundColor: themeColors.greyscale4,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: themeColors.greyscale3,
    },
  },
  cellEntity: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'left',
    margin: '-9px 0px',
  },
  withoutPadding: {
    padding: '0px',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  headerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    justifyContent: 'flex-end',
  },
});
