import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { useStyles } from './PourEventCardsPage.styles';
import { CreateProjectModal } from '../projects/components/CreateProjectModal';
import { useProjectsApi } from 'app/shared/hooks/useProjectsApi';
import { Routes, NotificationEntityType, IHistoryRecord } from 'types';
import {
  useBatchApi,
  useBatchHistoryList,
  useNotificationEntityApi,
  useScreen,
} from 'app/shared/hooks';
import { TablePagination } from 'app/shared/components/generic-ui/Table/TablePagination';
import { firebaseAnalytics } from 'app/shared/analytics';
import { PageHeader } from '../../shared/components/generic-ui/PageHeader/PageHeader';
import { format, startOfDay } from 'date-fns';
import { useAuth } from 'app/shared/providers';
import {
  CellAlignment,
  CellSize,
  PaginationType,
} from 'app/shared/components/generic-ui/Table/Table.types';
import {
  EscapeAction,
  escapePercentSign,
  getNotificationEntityName,
  useSearchHelper,
} from 'app/shared/utils';
import { Search } from 'app/shared/components/generic-ui/Search/Search';
import { Button } from 'app/shared/components/generic-ui/Button/Button';
import { Grid } from 'app/shared/components/generic-ui';
import { FilterBlock } from './FilterBlock';
import { PourEventCardSection } from './PourEventCardSection/PourEventCardSection';
import { SectionSpoiler } from 'app/shared/components/generic-ui/SectionSpoiler';
import { CircularProgress } from 'app/shared/components/generic-ui/CircularProgress';
import { BatchList } from '../batches/components';
import { Checkbox } from '@material-ui/core';
import { ReactComponent as RadioIcon } from 'assets/radio_icon.svg';
import { ReactComponent as RadioEmpty } from 'assets/radio_empty.svg';

const emptyPourEvents = 0;
const minRowsForPagination = 8;
const breakpoint = 600;
const skipOnePage = 1;
const emptyRows = 0;

export const PourEventCardsPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { isMember, userId } = useAuth();
  const {
    organizationId,
    entityType,
    notificationEntityId,
    page,
    perPage,
    name: rawName,
    externalId,
  }: {
    organizationId: string;
    entityType: string;
    page: string;
    perPage: string;
    notificationEntityId: string;
    name: string;
    externalId: string;
  } = useParams();
  const name = decodeURIComponent(rawName);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { prepareSearchQueryParameter } = useSearchHelper();
  const { isMobile } = useScreen(breakpoint);
  const [checked, setChecked] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [batchList, setBatchList] = useState<IHistoryRecord[]>();
  const [totalCount, setTotalCount] = useState<number>(emptyRows);
  const [toJobRemaining, setToJobRemaining] = useState(false);
  const [predictions, setPredictions] = useState(false);

  const from = localStorage.getItem(
    `${notificationEntityId}${entityType}:from`,
  );
  const to = localStorage.getItem(`${notificationEntityId}${entityType}:to`);
  const userAssigned = localStorage.getItem(
    `${notificationEntityId}${entityType}:userAssigned`,
  );
  const entityName = getNotificationEntityName(Number(entityType));
  const [search, setSearch] = useState('');

  const [isFilterOpened, setIsFilterOpened] = useState(true);
  const [startDate, setStartDate] = useState<Date | undefined | null>(
    from === 'null' ? new Date() : from ? new Date(from) : null,
  );
  const [endDate, setEndDate] = useState<Date | undefined | null>(
    !to || to === 'null' ? null : new Date(to),
  );
  const [userAssignedTickets, setUserAssignedTickets] = useState<boolean>(
    !!userAssigned,
  );

  const isFilteredHistory =
    Number(entityType) === NotificationEntityType.DRIVER ||
    Number(entityType) === NotificationEntityType.VEHICLE ||
    Number(entityType) === NotificationEntityType.MIX_DESIGN ||
    Number(entityType) === NotificationEntityType.POUR_EVENT;

  const dateFilterFormat = useCallback((date: string | undefined | null) => {
    return date
      ? format(startOfDay(new Date(date)), "yyyy-MM-dd'T'HH:mm:ssxxx")
      : '';
  }, []);

  const history = useHistory();
  const queryProjectParams = useMemo(() => {
    return {
      organizationId: organizationId,
      queryParameters: {
        projectId: notificationEntityId,
      },
    };
  }, [notificationEntityId, organizationId]);

  const searchQueryParams = useMemo(() => {
    const params = {
      entityType: Number(entityType),
      organizationId,
      queryParameters: {
        take: '10',
        skip: '0',
        sortBy: 'lastTicketDispatchDateTimeUtc',
        sortAsc: false,
        notificationEntityId,
        query: prepareSearchQueryParameter(search),
      },
    };
    if (userAssignedTickets) {
      //@ts-ignore
      params.queryParameters.userId = userId;
    }
    return params;
  }, [
    entityType,
    organizationId,
    userAssignedTickets,
    userId,
    notificationEntityId,
    search,
    prepareSearchQueryParameter,
  ]);

  const queryParams = useMemo(() => {
    const params = {
      entityType: Number(entityType),
      organizationId,
      queryParameters: {
        take: perPage,
        skip: `${(+page - skipOnePage) * +perPage}`,
        sortBy: 'lastTicketDispatchDateTimeUtc',
        sortAsc: false,
        notificationEntityId,
        startDateTime: startDate
          ? encodeURIComponent(dateFilterFormat(startDate.toISOString()))
          : '',
        endDateTime: endDate
          ? encodeURIComponent(dateFilterFormat(endDate.toISOString()))
          : !endDate && startDate
          ? encodeURIComponent(dateFilterFormat(startDate.toISOString()))
          : '',
      },
    };
    if (userAssignedTickets) {
      //@ts-ignore
      params.queryParameters.userId = userId;
    }
    return params;
  }, [
    entityType,
    organizationId,
    userAssignedTickets,
    userId,
    notificationEntityId,
    page,
    perPage,
    dateFilterFormat,
    endDate,
    startDate,
  ]);

  const batchHistoryParams = useMemo(() => {
    const params = {
      skip: `${(+page - skipOnePage) * +perPage}`,
      take: perPage,
      startDateTime: startDate ? dateFilterFormat(startDate.toISOString()) : '',
      endDateTime: endDate
        ? dateFilterFormat(endDate.toISOString())
        : !endDate && startDate
        ? dateFilterFormat(startDate.toISOString())
        : '',
      lastElementDateTimeUtc: '',
      ticketsWithStatusesOnly: checked,
      plants: [],
      mixDesigns:
        Number(entityType) === NotificationEntityType.MIX_DESIGN
          ? [{ id: externalId, name: '' }]
          : [],
      vehicles:
        Number(entityType) === NotificationEntityType.VEHICLE
          ? [{ id: externalId, name: '' }]
          : [],
      drivers:
        Number(entityType) === NotificationEntityType.DRIVER
          ? [{ id: externalId, name: '' }]
          : [],
      customers: [],
      pourEvents:
        Number(entityType) === NotificationEntityType.POUR_EVENT
          ? [{ id: notificationEntityId, name: '' }]
          : [],
      projects: [],
      eventStatusCodes: [],
      toJobRemaining,
      predictions,
      users: [],
      notInMixDesigns: [],
      notInPlants: [],
      notInVehicles: [],
      notInDrivers: [],
      notInCustomers: [],
      notInPourEvents: [],
      notInProjects: [],
      notInEventStatusCodes: [],
      notInUsers: [],
    };

    return userAssignedTickets
      ? {
          ...params,
          userId,
          crossmatching: false,
        }
      : { ...params, crossmatching: true };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dateFilterFormat,
    endDate,
    userAssignedTickets,
    page,
    perPage,
    startDate,
    userId,
    checked,
    refresh,
    externalId,
    toJobRemaining,
    predictions,
  ]);

  const columns = [
    {
      id: 'name',
      label: t(translations.notificationEntityPage.name),
      alignment: CellAlignment.LEFT,
      size: CellSize.MEDIUM,
    },
  ];

  const { fetchProject, project, deleteProject } =
    useProjectsApi(queryProjectParams);

  const {
    fetchPourEventCards,
    pourEventCards,
    pourEventCardsMetadata,
    isFetchingPourEventCards,
  } = useNotificationEntityApi(queryParams);

  const {
    fetchPourEventCards: fetchPourEventCardsSearch,
    pourEventCards: pourEventCardsSearch,
    isFetchingPourEventCards: isFetchingPourEventCardsSearch,
  } = useNotificationEntityApi(searchQueryParams);

  const editProjectHandler = useCallback(() => {
    firebaseAnalytics.logPressEvent(
      Routes.ProjectEvents,
      'Edit project details action',
    );

    setIsModalOpen(true);
  }, []);

  const applyDateHandler = useCallback(
    (
      from?: Date | null | undefined,
      to?: Date | null | undefined,
      userAssigned?: boolean,
    ) => {
      firebaseAnalytics.logPressEvent(
        Routes.NotificationEntityPourEventsPage,
        `Apply date range from ${entityName}`,
        JSON.stringify({
          startDate: from,
          endDate: to,
        }),
      );
      history.push(
        generatePath(Routes.NotificationEntityPourEventsPage, {
          entityType,
          notificationEntityId,
          organizationId,
          page: 1,
          perPage: 20,
          name,
          externalId,
        }),
      );
      setStartDate(from);
      setEndDate(to);
      setUserAssignedTickets(!!userAssigned);
    },
    [
      entityName,
      entityType,
      history,
      notificationEntityId,
      organizationId,
      name,
      externalId,
    ],
  );

  const refreshHandler = useCallback(() => {
    setRefresh(prevState => !prevState);
  }, []);

  const changeCheckboxHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setChecked(event.target.checked);

      history.push({
        pathname: Routes.Batches.replace(
          ':organizationId/:page/:perPage',
          `${organizationId}/1/20`,
        ),
        search: location.search,
      });
    },
    [history, organizationId],
  );

  const { fetchBatchesHistory, isFetchingBatchesHistory } = useBatchHistoryList(
    {
      organizationId: organizationId,
    },
  );

  const { fetchMapHeader, mapHeader, isFetchingMapHeader } = useBatchApi({
    organizationId: organizationId,
  });

  const deleteProjectHandler = useCallback(async () => {
    firebaseAnalytics.logPressEvent(
      Routes.ProjectEvents,
      'Delete project action',
    );

    const deleteQuery = await deleteProject();
    if (!deleteQuery.isError) {
      history.push(
        generatePath(Routes.NotificationEntityPage, {
          organizationId,
          entityType: NotificationEntityType.PROJECT,
        }),
      );
    }
  }, [deleteProject, history, organizationId]);

  useEffect(() => {
    if (!isMember && Number(entityType) === NotificationEntityType.PROJECT) {
      fetchProject();
    }
  }, [fetchProject, isMember, notificationEntityId, isModalOpen, entityType]);

  useEffect(() => {
    if (isFilteredHistory) {
      fetchMapHeader();
      fetchBatchesHistory(batchHistoryParams).then(res => {
        setTotalCount(res.metadata?.totalCount || emptyRows);
        setBatchList(res.data);
      });
    } else {
      fetchPourEventCards();
    }
  }, [
    fetchPourEventCards,
    fetchMapHeader,
    fetchBatchesHistory,
    queryParams,
    isFilteredHistory,
    batchHistoryParams,
    toJobRemaining,
    predictions,
  ]);

  useEffect(() => {
    if (!isFilteredHistory) {
      fetchPourEventCardsSearch();
    }
  }, [fetchPourEventCardsSearch, isFilteredHistory, searchQueryParams]);

  const checkbox = useMemo(() => {
    return (
      <div className={classes.checkbox}>
        <Checkbox
          checkedIcon={<RadioIcon />}
          icon={<RadioEmpty />}
          onChange={changeCheckboxHandler}
          checked={checked}
        />
        <CustomTypography variant="bodySmall" color="accentDark">
          {t(translations.batchesPage.showBatchesWithStatuses)}
        </CustomTypography>
      </div>
    );
  }, [changeCheckboxHandler, checked, classes.checkbox, t]);

  return (
    <PageHeader
      header={name || ''}
      editHandler={editProjectHandler}
      deleteHandler={deleteProjectHandler}
      route={Routes.ProjectEvents}
      withTabNavigation={false}
      withActions={
        !isMember && Number(entityType) === NotificationEntityType.PROJECT
      }
    >
      {isModalOpen && (
        <CreateProjectModal
          isOpen={isModalOpen}
          closeHandler={() => {
            setIsModalOpen(false);
          }}
          project={project}
        />
      )}

      <div className={classes.subheader} data-testid="subheader">
        <CustomTypography variant="header4" bold color="accentDark">
          {t(
            isFilteredHistory
              ? translations.projectEventsPage.batches
              : translations.projectEventsPage.pourEvents,
          )}
        </CustomTypography>
      </div>
      <div className={classes.searchContainer}>
        <Grid container md={12} lg={12}>
          <Grid container item xs={10} sm={8} md={9} lg={11} spacing={1}>
            <Grid item md={12} lg={5} className={classes.gridLeft}>
              <div className={classes.search}>
                {!isFilteredHistory && (
                  <Search
                    columns={columns}
                    entries={pourEventCardsSearch}
                    onChange={setSearch}
                    isLoading={isFetchingPourEventCardsSearch}
                    placeholder={t(
                      translations.notificationEntityPage.searchByNameOrId,
                      {
                        entityType: getNotificationEntityName(
                          NotificationEntityType.POUR_EVENT,
                        ).toLowerCase(),
                      },
                    )}
                    onClickRow={value => {
                      const formattedId = encodeURIComponent(value.internalId);
                      const formattedName = encodeURIComponent(
                        escapePercentSign(EscapeAction.ENCODE, value.name),
                      );

                      history.push({
                        pathname: Routes.SinglePourEvent.replace(
                          ':organizationId/:id/:pourEventId/:externalProductId/:name/:page/:perPage',
                          `${organizationId}/${notificationEntityId}/${formattedId}/${value.mixDesigns[0].externalProductId}/${formattedName}/1/20`,
                        ),
                      });
                    }}
                    getRowId={'projectId'}
                    analyticsReference={Routes.NotificationEntityPage}
                  />
                )}
              </div>
            </Grid>
          </Grid>
          <Grid item xs={2} sm={4} md={3} lg={1} className={classes.gridRight}>
            <Button
              onClick={() => {
                setIsFilterOpened(prevState => !prevState);
              }}
              variant={
                !isMobile
                  ? isFilterOpened
                    ? 'filterActive'
                    : 'filter'
                  : isFilterOpened
                  ? 'filterSmallActive'
                  : 'filterSmall'
              }
              icon="filter"
              active={isFilterOpened}
            >
              {!isMobile ? (
                <div className={classes.labelContainer}>
                  <CustomTypography
                    variant="buttonTextSmall"
                    bold
                    color={isFilterOpened ? 'white' : 'mbsBlue'}
                  >
                    {t(translations.notificationEntityPage.filter)}
                  </CustomTypography>
                </div>
              ) : undefined}
            </Button>
          </Grid>
        </Grid>
      </div>
      {isFilterOpened && <FilterBlock applyHandler={applyDateHandler} />}

      {isFilteredHistory && (
        <SectionSpoiler
          withIcons={false}
          header={t(translations.batchesPage.allBatches)}
          subHeaders={[{ element: checkbox }]}
        >
          {!isFetchingMapHeader && mapHeader ? (
            <BatchList
              statuses={mapHeader}
              batchList={batchList}
              isLoading={isFetchingBatchesHistory}
              rowsTotal={totalCount}
              setToJobRemaining={setToJobRemaining}
              setPredictions={setPredictions}
              refreshHandler={refreshHandler}
              baseURL={Routes.NotificationEntityPourEventsPage.replace(
                ':entityType/:organizationId/:notificationEntityId/:externalId/:name',
                `${entityType}/${organizationId}/${notificationEntityId}/${externalId}/${encodeURIComponent(
                  name,
                )}`,
              )}
            />
          ) : (
            <CircularProgress withLabel />
          )}
        </SectionSpoiler>
      )}

      {!isFilteredHistory && (
        <PourEventCardSection
          pourEventCards={pourEventCards}
          isLoading={isFetchingPourEventCards}
        />
      )}

      {!!pourEventCardsMetadata?.totalCount &&
        pourEventCardsMetadata?.totalCount >= minRowsForPagination &&
        !isFilteredHistory && (
          <TablePagination
            paginationType={PaginationType.MAIN}
            count={pourEventCardsMetadata?.totalCount || emptyPourEvents}
            baseURL={Routes.NotificationEntityPourEventsPage.replace(
              ':entityType/:organizationId/:notificationEntityId/:externalId/:name',
              `${entityType}/${organizationId}/${notificationEntityId}/${externalId}/${encodeURIComponent(
                name,
              )}`,
            )}
            withPerPageCount={false}
          />
        )}
    </PageHeader>
  );
};
