import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  wrapper: {
    margin: '0px 30px 140px 30px',
    boxSizing: 'border-box',
  },
  header: {
    marginBottom: 8,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  progress: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 400,
  },
  checkbox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '8px',
  },
});
