import { useCallback, useMemo } from 'react';
import { IProbeValue } from 'types';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';

const defaultDecimalPlaces = 3;
const precisionBase = 10;

export function useProbeDataFormatter() {
  const { t } = useTranslation();

  const getTranslatedUnit = useCallback(
    (unit: string | null) => {
      let result = '';

      if (unit) {
        result = translations.units.hasOwnProperty(unit)
          ? t(`units.${unit}`)
          : unit;
      }

      return result;
    },
    [t],
  );

  const formatProbeValue = useCallback(
    (
      number: number | null,
      decimalPlaces: number = defaultDecimalPlaces,
      method: 'floor' | 'round' | 'ceil' = 'round',
      isFixed = false,
    ) => {
      let result = '-';

      if (number !== null) {
        const precision = Math.pow(precisionBase, decimalPlaces);
        const preProcessedNumber = Math[method](number * precision) / precision;

        if (isFixed) {
          result = preProcessedNumber.toFixed(decimalPlaces);
        } else {
          result = preProcessedNumber.toString();
        }
      }

      return result;
    },
    [],
  );

  const renderProbeData = useCallback(
    (
      data: IProbeValue | number | null | undefined,
      decimalPlaces: number = defaultDecimalPlaces,
      isFixed: boolean | undefined = false,
    ) => {
      let result = '-';

      if (data != null) {
        if (typeof data === 'number') {
          result = formatProbeValue(data);
        } else if ((data as IProbeValue).value !== null) {
          if (!(data as IProbeValue).measurement) {
            result = (data as IProbeValue).value as string;
          } else {
            result = `${formatProbeValue(
              (data as IProbeValue).value as number,
              decimalPlaces,
              undefined,
              isFixed,
            )} ${getTranslatedUnit((data as IProbeValue).measurement)}`;
          }
        } else if ((data as IProbeValue).value === null && data.measurement) {
          result = data.measurement;
        }
      }

      return result;
    },
    [formatProbeValue, getTranslatedUnit],
  );

  return useMemo(
    () => ({
      renderProbeData,
      getTranslatedUnit,
      formatProbeValue,
    }),
    [renderProbeData, getTranslatedUnit, formatProbeValue],
  );
}
