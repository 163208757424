import { makeStyles } from '@material-ui/core';
import { themeColors } from 'app/shared/theme/theme';

export const useStyles = makeStyles({
  paper: {
    boxShadow: 'none',
    backgroundColor: themeColors.greyscale5,
    borderColor: 'white',
    width: '100%',
    height: '100%',
  },
  root: {
    overflow: 'auto',
    maxHeight: '75%',

    '&::-webkit-scrollbar': {
      height: '5px',
      width: '5px',
      backgroundColor: themeColors.greyscale4,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: themeColors.greyscale3,
    },
  },
  nameContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  cellVariant: {
    padding: '8px 0px 8px 16px !important',
  },
  cellVariantWithCheckbox: {
    padding: '0px 0px 0px 15px',
    width: '15px',
  },
  row: {
    backgroundColor: `${themeColors.highlightBlue} !important`,
  },
  rowBackground: {
    '&:nth-of-type(even)': {
      backgroundColor: themeColors.greyscale5,
    },
  },
  hover: {
    backgroundColor: `${themeColors.highlightBlue} !important`,
  },

  checkedIcon: {
    color: `${themeColors.mbsBlue} !important`,
  },
  checkbox: {
    padding: '10px 0px 10px 11px',
  },
  icon: {
    color: themeColors.greyscale,
    '&:hover': {
      backgroundColor: `${themeColors.highlightBlue} !important`,
    },
  },
  iconNegative: {
    color: themeColors.greyscale,
    '&:hover': {
      backgroundColor: `${themeColors.highlightRed} !important`,
    },
  },
  checkedNegativeIcon: {
    color: `red !important`,
  },

  cell: {
    padding: '8px 16px',
  },
  cellWithoutCheckbox: {
    padding: '15px 16px',
  },
  cellWithoutPadding: {
    padding: '5px 16px',
  },
  withoutPadding: {
    padding: '5px',
  },
  emptyTableCell: {
    height: 150,
  },
  emptyTableCellNoBorder: {
    height: 150,
    borderBottom: 'none',
  },
});
